import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/organizations';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const UploadJson = props => {
    const { closeDrawer, organization, uploading } = props;
    const { uploadJson } = props;

    const formSchema = [
        {
            accept: 'application/json\n',
            label: 'File\x2a',
            name: 'file',
            type: 'file',
            validate: [validators.required]
        }
    ];

    const onSubmit = values => {
        const data = serializeForm(values, formSchema);
        uploadJson(organization.id, data.file, closeDrawer);
    };

    return (
        <FormViewer label="Upload JSON File">
            <a href="/static/example.json" target="_blank">Download example file</a>
            <br/>
            <br/>
            <Form
                buttonLabel="Upload"
                formSchema={formSchema}
                isSubmitting={uploading}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = state => ({
    uploading: state.organizations.uploadingJson
});

const mapDispatchToProps = ({
    uploadJson: actions.uploadJson
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadJson);
