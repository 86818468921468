import { apiRequest } from 'utils/request';

export default async () => {
    try {
        const userResponse = await apiRequest('/users/me');
        return Promise.resolve(userResponse.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
