import columns from 'utils/columns/equipmentCategories';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { EQUIPMENT_CATEGORY } from './actionTypes';

// actions
const actions = actionsFactory(EQUIPMENT_CATEGORY);
export { actions };

// reducer
const initialState = initialStateFactory(columns);
const reducer = reducerFactory(EQUIPMENT_CATEGORY);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(EQUIPMENT_CATEGORY, '/equipment_categories');
export { sagas };
