import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import IconButton from 'Components/Buttons/IconButton';
import React from 'react';
import styles from './styles';

const Component = props => {
    const { buttons = [], classes, children, header, realtime } = props;
    const { setRealtime } = props;

    let Buttons;
    if (buttons.length) {
        Buttons = (
            <div className={classes.toolbar}>
                {buttons.map(button => <IconButton key={button.icon} {...button} />)}
            </div>
        );
    }

    if (setRealtime) {
        Buttons = (
            <div className={classes.toolbar}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={realtime}
                            name="checked"
                            onChange={event => setRealtime(event.target.checked)}
                            size="small"
                        />
                    }
                    label="Pool Data"
                />
            </div>
        );
    }

    let Header;
    if (header) {
        Header = (
            <Typography className={classes.header} component="h6" variant="h6">
                {header}
            </Typography>
        );
    }

    return (
        <Paper className={classes.paper}>
            {Buttons}
            {Header}
            <div className={Header ? classes.detailsHeader : classes.details}>
                {children}
            </div>
        </Paper>
    );
};

export default withStyles(styles)(Component);
