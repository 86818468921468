import TabsToolbar from 'Components/TabsToolbar';
import React from 'react';

const Toolbar = ({ currentTab, device }) => {
    const deviceTabs = [{ text: 'Details', id: 'details', url: `/devices/${device.id}/details` }];

    if (device.out_protocol_name === 'Acting Metal' || device.out_protocol_name === 'Reading Metal') {
        deviceTabs.push({ text: 'Measurements', id: 'measurements', url: `/devices/${device.id}/measurements` });
    } else if (device.out_protocol_name === 'Digital') {
        deviceTabs.push({ text: 'Measurements', id: 'measurements', url: `/devices/${device.id}/measurements` });
        deviceTabs.push({ text: 'Devices', id: 'devices', url: `/devices/${device.id}/devices` });
    } else {
        deviceTabs.push({ text: 'Devices', id: 'devices', url: `/devices/${device.id}/devices` });
    }

    if (device.out_protocol_name !== 'Acting Metal') {
        deviceTabs.push({ text: 'Realtime', id: 'realtime', url: `/devices/${device.id}/realtime` });
    }

    deviceTabs.push({ text: 'Logs', id: 'logs', url: `/devices/${device.id}/logs` });

    return (
        <TabsToolbar currentTab={currentTab} tabs={deviceTabs}/>
    );
};

export default Toolbar;
