import authenticationReducer from 'ducks/authentication';
import bootstrapReducer from 'ducks/bootstrap';
import buildingsReducer from 'ducks/buildings';
import deploysReducer from 'ducks/deploys';
import deviceLogsReducer from 'ducks/deviceLogs';
import devicesReducer from 'ducks/devices';
import drawerViewReducer from 'ducks/drawerView';
import equipmentReducer from 'ducks/equipment';
import equipmentCategoriesReducer from 'ducks/equipmentCategories';
import formInitializerReducer from 'ducks/formInitializer';
import loadBuildingReducer from 'ducks/loadBuilding';
import logReducer from 'ducks/logs';
import measurementsReducer from 'ducks/measurements';
import metadataReducer from 'ducks/metadata';
import metadataRulesReducer from 'ducks/metadataRules';
import organizationsReducer from 'ducks/organizations';
import protocolsReducer from 'ducks/protocols';
import readingsReducer from 'ducks/readings';
// TODO remove:template import templatesReducer from 'ducks/templates';
import uiReducer from 'ducks/ui';
import userFeedback from 'ducks/userFeedback';
import usersReducer from 'ducks/users';
import wiseBoxesReducer from 'ducks/wise_boxes';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

const appReducer = combineReducers({
    userFeedback: userFeedback,
    auth: authenticationReducer,
    bootstrap: bootstrapReducer,
    buildings: buildingsReducer,
    deploys: deploysReducer,
    deviceLogs: deviceLogsReducer,
    devices: devicesReducer,
    drawerView: drawerViewReducer,
    equipmentCategories: equipmentCategoriesReducer,
    equipments: equipmentReducer,
    form: formReducer,
    formInitializers: formInitializerReducer,
    loadedBuildings: loadBuildingReducer,
    logs: logReducer,
    measurements: measurementsReducer,
    metadataRules: metadataRulesReducer,
    metadatas: metadataReducer,
    organizations: organizationsReducer,
    protocols: protocolsReducer,
    readings: readingsReducer,
    // TODO remove:template templates: templatesReducer,
    ui: uiReducer,
    users: usersReducer,
    wise_boxes: wiseBoxesReducer
});

// https://stackoverflow.com/a/35641992
export default (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined;
    }

    return appReducer(state, action);
};
