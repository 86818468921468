import axios from 'axios';
import { isArray } from 'lodash';

export const apiRequest = (url = '/', method = 'GET', data) => {
    const requestOptions = {
        method: method,
        url: process.env.API_URL + url,
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*'
        },
        withCredentials: true
    };

    if (data) {
        requestOptions['data'] = data;
    }

    return axios(requestOptions);
};

export const apiRequestWithFile = (url = '/', method = 'GET', data) => {
    let formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key]);
    }

    const requestOptions = {
        data: formData,
        method: method,
        url: process.env.API_URL + url,
        withCredentials: true
    };

    return axios(requestOptions);
};

export const uploadFiles = (url = '/', method = 'PUT', payload) => {
    if (!payload) {
        return Promise.reject('invalid payload');
    }

    if (isArray(payload) && payload.length === 0) {
        return Promise.reject('invalid number of files');
    }

    const requestOptions = {
        method: method,
        url: process.env.API_URL + url,
        withCredentials: true
    };

    const data = new FormData();
    if (isArray(payload)) {
        payload.forEach((file, index) => {
            data.append(`files[${index}]`, file);
        });
    } else {
        data.append('file', payload);
    }

    requestOptions.data = data;
    return axios(requestOptions);
};
