import SimpleList from 'Components/SimpleList';
import React from 'react';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const properties = model => [
    { label: 'ID', content: `${model.id}` },
    { label: 'Name', content: model.name },
    { label: 'Country', content: model.country },
    { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Details = props => {
    const { model } = props;

    return (
        <SimpleList content={properties(model)}/>
    );
};

export default Details;
