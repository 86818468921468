import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/equipment';
import React from 'react';
import { connect } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { currentUser, metadatas, model } = props;

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        ...metadataFields(metadatas, model)
    ];

    if (model.usable && currentUser.superuser) {
        formSchema.push(
            {
                type: 'checkBox',
                label: 'Usable Equipment?',
                name: 'usable'
            });
    }

    return (
        <EntityManageView
            formSchema={formSchema}
            formInitialValues={model}
            label="Edit Equipment"
            {...props}
        />
    );
};

const mapStateToProps = state => ({
    currentUser: state.users.models[state.auth.currentUserId],
    metadatas: selectorMetadatas.metadataFromEquipment(state),
    model: state.equipments.models[state.drawerView.entityId]
});

const mapDispatchToProps = ({
    create: actions.create,
    update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
