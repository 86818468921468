import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import SimpleList from 'Components/SimpleList';
import EditDevice from 'Pages/Tree/Device/Edit';
import React, { useState } from 'react';
import { unixToDate } from 'utils/date';
import formatDescription from 'utils/formatDescription';
import getUserName from 'utils/getUserName';
import statusToComponent from 'utils/statusToComponent';
import icons from 'utils/ui/icons';

const Component = props => {
    const { childDevices, device, submitting } = props;
    const { remove } = props;

    const [drawer, setOpenDrawer] = useState(null);
    const closeDrawer = () => setOpenDrawer(null);

    const deviceAttributes = [
        { label: 'ID', content: device.id },
        { label: 'Equipment', content: device.equipment_name },
        { label: 'Description', content: formatDescription(device.description) },
        { label: 'Name', content: device.name },
        { label: 'Status', content: statusToComponent(device.status) },
        { label: 'Created', content: `${unixToDate(device.created_at)} by ${getUserName(device.created_by)}` },
        { label: 'Updated', content: unixToDate(device.updated_at) }
    ];

    const deviceButtons = [];

    deviceButtons.push(
        {
            icon: icons.edit,
            onClick: () => setOpenDrawer('editDevice'),
            tooltip: 'Edit'
        }
    );

    if (childDevices.length === 0) {
        deviceButtons.push(
            {
                confirmationHeader: 'Delete Confirmation',
                confirmationText: 'Are you sure you want to delete?',
                submitting: submitting,
                icon: icons.trash,
                onClick: () => remove(device.id, `/buildings/${device.building_id}`),
                tooltip: 'Delete'
            }
        );
    }

    let DrawerContent;
    if (drawer === 'editDevice') {
        DrawerContent = (
            <EditDevice id={device.id} closeDrawer={closeDrawer}/>
        );
    }

    return (
        <>
            <Panel buttons={deviceButtons}>
                <SimpleList content={deviceAttributes}/>
            </Panel>
            <Drawer onClose={closeDrawer} open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </>
    );
};

export default Component;
