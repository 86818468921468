export default theme => ({
    buttonProgress: {
        color: theme.palette.primary.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative'
    }
});
