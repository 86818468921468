import Circle from 'Components/Circle';
import React from 'react';

const WiseLed = ({ code = 0 }) => {
    const array = (code || 0).toString(2).padStart(3, '0').split('').map(Number);

    return [0, 1, 2].map(index =>
        <>
            <Circle key={index} color={array[index] === 1 ? '#B94A48' : '#AAAAAA'} size="lg"/>
            &nbsp;
        </>
    );
};

export default WiseLed;
