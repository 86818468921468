import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/equipmentCategories';
import Show from 'Pages/EquipmentCategories/Show';
import React from 'react';
import { connect } from 'react-redux';
import columns from 'utils/columns/equipmentCategories';

const EquipmentCategoriesPage = props => {
    let { ...rest } = props;

    return (
        <EntitiesView
            columns={columns}
            showComponent={Show}
            {...rest}
        />
    );
};

const mapStateToProps = ({ equipmentCategories: { allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection } }) =>
    ({ allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection });

const { changeSearch, changeSort, fetchAll, toggleAll, toggleOne } = actions;

const mapDispatchToProps = ({
    changeSearch,
    changeSort,
    fetchData: fetchAll,
    toggleAll,
    toggleOne: event => {
        event.stopPropagation();
        return toggleOne(event.target.name);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentCategoriesPage);



