import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { isEqual, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';

const Component = props => {
    const { device, equipment, submitting } = props;
    const { replace } = props;

    const
        currentEquipment = equipment[device.equipment_id],
        equipmentList = Object.values(equipment)
        .filter(equipment => {
            const { equipment_category_id, id } = equipment;

            return id !== currentEquipment.id && isEqual(equipment_category_id, currentEquipment.equipment_category_id);
        });

    if (equipmentList.length) {
        const formSchema = [
            {
                type: 'select',
                label: 'New Equipment',
                name: 'equipment_id',
                formData: sortBy(equipmentList, 'name')
            }
        ];

        const onSubmit = values => {
            const data = serializeForm(values, formSchema);
            if (data.equipment_id) {
                replace(device.id, data.equipment_id);
            }
        };

        return (
            <FormViewer label="Replace Device">
                <Form
                    formSchema={formSchema}
                    isSubmitting={submitting}
                    onSubmit={onSubmit}
                />
            </FormViewer>
        );
    } else {
        return (
            <div>
                No compatible equipment found. Unable to replace automatically, please do it manually.
            </div>
        );
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        device: state.devices.models[ownProps.id],
        equipment: state.equipments.models,
        submitting: state.devices.submitting
    };
};

const mapDispatchToProps = ({
    replace: deviceActions.replace
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);

Component.propTypes = {
    id: PropTypes.string.isRequired
};
