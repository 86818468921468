import React from 'react';

const PanelDivider = () => {

    return (
        <div style={{ height: 10 }}>
            &nbsp;
        </div>
    );
};

export default PanelDivider;
