import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

const FORM = 'form';

const equipments = state => state.equipments.models;
const metadatas = state => state.metadatas.models;
const metadataRules = state => state.metadataRules.models;

const getFormValues = state => (state.form && state.form[FORM] && state.form[FORM].values) && state.form[FORM].values;
const getModel = reducer => (state, ownProps = {}) => state[reducer].models[ownProps.id || state.drawerView.entityId];
const entityId = state => state.drawerView.entityId;

const metadataFromEntity = (model, entityName) => createSelector(
    model, entityName, equipments, metadatas, metadataRules,
    (model, entityName, equipments, metadatas, metadataRules) => {
        const
            equipmentCategoryId = entityName === 'equipment' ? model.equipment_category_id : (equipments[model.equipment_id] || {}).equipment_category_id,
            rule = Object.values(metadataRules).find(rule => rule.entity === entityName && rule.equipment_category_id === equipmentCategoryId);

        if (!rule) {
            return [];
        }

        return rule.metadata_ids.map(id => {
            const metadata = metadatas[id];

            return {
                enumerator_members: metadata.enumerator_members,
                id: id,
                metadata_rule_id: rule.id,
                metadata_type: metadata.metadata_type,
                modifiable: rule.modifiable,
                name: metadata.name
            };
        });
    }
);

// equipment
const modelEquipment = createSelector(
    getFormValues, getModel('equipments'),
    (formValues, model) => ({ ...model, ...formValues })
);
export const metadataFromEquipment = metadataFromEntity(modelEquipment, () => 'equipment');

// reading
const modelReading = createSelector(
    getFormValues, getModel('readings'), entityId,
    (formValues, model, entityId) => ({ equipment_id: model ? model.equipment_id : entityId, ...model, ...formValues })
);
export const metadataFromReading = metadataFromEntity(modelReading, () => 'reading');

// device
const modelDevice = createSelector(
    getFormValues, getModel('devices'),
    (formValues, model) => ({ ...model, ...formValues })
);
export const metadataFromDevice = metadataFromEntity(modelDevice, () => 'device');

// measurement
const modelMeasurement = createSelector(
    getFormValues, getModel('measurements'),
    (state, ownProps) => (ownProps.device || {}).equipment_id,
    (formValues, model, equipmentId) => ({ equipment_id: equipmentId, ...model, ...formValues })
);
export const metadataFromMeasurement = metadataFromEntity(modelMeasurement, () => 'measurement');

// default metadata
const metadatasArray = createSelector(metadatas, metadatas => sortBy(Object.values(metadatas), 'name'));

export default {
    metadataFromDevice,
    metadataFromReading,
    metadataFromMeasurement,
    metadataFromEquipment,
    metadatasArray
};
