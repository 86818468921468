export const HEADER_HEIGHT = 64;

// Building device tree view
export const TREE_HEADER_HEIGHT = 36;
export const SPACER_MINI = 8;

// Component Tabs
export const TABS_HEIGHT = 32; //48

export const EXPANDED_EXPANSION_PANEL_HEADER_NEW = 40;

// Buildings panel constants
export const PANEL_HEADER = 32;


