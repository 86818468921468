import Panel from 'Components/Panel';
import PanelDivider from 'Components/PanelDivider';
import Loading from 'Components/ScopedLoader';
import { actions as deployActions } from 'ducks/deploys';
import { actions as deviceActions } from 'ducks/devices';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BalenaDetails from './BalenaDetails';
import Table from './Table';

const Component = props => {
    const { balenaStatus, device, deploys, loading, submitting } = props;
    const { deploy, fetchAll, getBalenaStatus } = props;

    const syncable = balenaStatus?.manageable || process.env.ENVIRONMENT === 'development' || false;

    useEffect(() => {
        fetchAll({ device_id: device.id });
    }, [device.id]);

    useEffect(() => {
        getBalenaStatus(device.id);
    }, [device.id]);

    if (loading) {
        return (
            <Panel>
                <Loading/>
            </Panel>
        );
    }
    return (
        <>
            <BalenaDetails balenaStatus={balenaStatus} device={device} submitting={submitting}/>
            <PanelDivider/>
            <Table deploy={deploy} deploys={deploys} device={device} submitting={submitting} syncable={syncable}/>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        balenaStatus: state.devices.balenaStatus[ownProps.device.id],
        deploys: state.deploys.models,
        loading: state.deploys.loading || state.devices.loading,
        submitting: state.devices.submitting
    };
};

const mapDispatchToProps = ({
    deploy: deviceActions.deploy,
    getBalenaStatus: deviceActions.getBalenaStatus,
    fetchAll: deployActions.fetchAll
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
