import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Component = props => {
    const { classes, disabled, input, label, meta, ...rest } = props;

    return (
        <TextField
            {...rest}
            autoComplete="off"
            className={classes.textField}
            disabled={disabled}
            label={label}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            inputProps={{
                ...input,
                style: {
                    fontSize: '0.65rem'
                }
            }}
            InputProps={{
                style: {
                    height: 30,
                    paddingBottom: 15
                }
            }}
            InputLabelProps={{
                shrink: true,
                style: {
                    transform: 'translate(12px, -15px) scale(0.75)'
                }
            }}
            margin="normal"
            type="number"
            variant="filled"
        />
    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.object, //injected by Field from Redux forms to control value and callbacks
    label: PropTypes.string,
    meta: PropTypes.object //injected by Field from Redux forms to access interaction state
};
