import Drawer from 'Components/Drawer';
import TabsToolbar from 'Components/TabsToolbar';
import { actions as drawerViewActions } from 'ducks/drawerView';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import icons from 'utils/ui/icons';
import UploadJson from './UploadJson';

const Toolbar = props => {
    const { organization } = props;
    const { editEntity } = props;
    const [drawer, setOpenDrawer] = useState('');
    const closeDrawer = () => setOpenDrawer('');

    const
        handleEditOrganization = () => editEntity(organization.id, 'ORGANIZATION'),
        buttons = [
            { icon: icons.editConfig, onClick: handleEditOrganization, tooltip: 'Edit' },
            { icon: icons.upload, onClick: () => setOpenDrawer('uploadJson'), tooltip: 'Upload json file' }
        ];

    let DrawerContent;
    if (drawer === 'uploadJson') {
        DrawerContent = (
            <UploadJson organization={organization} closeDrawer={closeDrawer}/>
        );
    }

    return (
        <>
            <TabsToolbar buttons={buttons} tabs={[{ text: 'organization', id: 'details' }]}/>
            <Drawer open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </>
    );
};

const mapDispatchToProps = ({
    editEntity: drawerViewActions.editEntity
});

export default connect(null, mapDispatchToProps)(Toolbar);
