import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import TabsToolbar from 'Components/TabsToolbar';
import PropTypes from 'prop-types';
import React from 'react';
import icons from 'utils/ui/icons';
import styles from './styles';

const Component = props => {
    const { classes, label, onBack } = props;

    let buttons = [];
    if (onBack) {
        buttons = [
            { icon: icons.back, onClick: onBack, tooltip: 'Back' }
        ];
    }

    let tabs = [];
    if (label) {
        tabs = [{ text: label, id: 'none' }];
    }

    return (
        <Grid item xs={12} className={classes.mainContainer}>
            <Grid item xs={12} className={classes.headerContainer}>
                <TabsToolbar buttons={buttons} tabs={tabs}/>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>
                <Card className={classes.card}>
                    <CardContent classes={{ root: classes.cardContent }}>
                        {props.children}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    onBack: PropTypes.func
};
