import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as measurementActions } from 'ducks/measurements';
import React from 'react';
import { connect } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { device, measurement, metadatas, reading, submitting, validRates } = props;
    const { closeDrawer, update } = props;

    const formSchema = [];

    if (['WiseInput', 'WiseCurrent'].includes(device.equipment_category_name)) {
        formSchema.push({
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        });
    }

    formSchema.push(
        {
            formData: validRates.map(rate => ({ id: rate, name: rate })),
            label: 'Rate\x2a',
            name: 'rate',
            type: 'select',
            validate: [validators.required]
        }
    );

    if (measurement.a_formula) {
        formSchema.push({
            disabled: true,
            label: 'a_formula\x2a',
            name: 'a_formula',
            type: 'text',
            validate: [validators.required]
        });
    }

    let metadataSchemas = metadataFields(metadatas);
    if (device.equipment_category_name === 'WiseCurrent' && reading.reading_kind_name.startsWith('EA+')) {
        metadataSchemas = [];
    }
    formSchema.push(...metadataSchemas);

    const onSubmit = values => {
        update(
            measurement.id,
            serializeForm(values, formSchema, measurement),
            closeDrawer
        );
    };

    return (
        <FormViewer label="Edit Measurement">
            <Form
                formSchema={formSchema}
                formInitialValues={measurement}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = (state, ownProps) => ({
    metadatas: selectorMetadatas.metadataFromMeasurement(state, ownProps),
    reading: state.readings.models[ownProps.measurement.reading_id],
    submitting: state.measurements.submitting,
    validRates: state.bootstrap.valid_rates
});

const mapDispatchToProps = ({
    update: measurementActions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
