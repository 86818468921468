import padIp from 'utils/padIp';

const statusWeight = {
    down: 4,
    problem: 3,
    ok: 2,
    disabled: 1,
    unknown: 0
};

export default (a, b) => {
    const
        aStatus = statusWeight[a.status] || 0,
        bStatus = statusWeight[b.status] || 0;

    if (aStatus !== bStatus) {
        return aStatus < bStatus ? 1 : -1;
    }

    const
        aMbus = a.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'mbus address'),
        bMbus = b.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'mbus address');

    if (aMbus && bMbus) {
        return aMbus.value < bMbus.value ? -1 : 1;
    }

    const
        aModbus = a.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'modbus id'),
        bModbus = b.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'modbus id');

    if (aModbus && bModbus) {
        return aModbus.value < bModbus.value ? -1 : 1;
    }

    const
        aIp = a.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'ip'),
        bIp = b.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'ip');

    if (aIp && bIp) {
        return padIp(aIp.value) < padIp(bIp.value) ? -1 : 1;
    }

    return a.name < b.name ? -1 : 1;
};
