import { withStyles } from '@material-ui/core/styles';
import IconButton from 'Components/Buttons/IconButton';
import { refreshBuilding } from 'ducks/loadBuilding';
import { collapseTree, toggleProblems } from 'ducks/ui';
import React from 'react';
import { connect } from 'react-redux';
import icons from 'utils/ui/icons';
import history from '~/history';
import styles from './styles';

const Component = props => {
    const { buildingId, classes, collapseTree, devices, refreshBuilding, toggleProblems } = props;

    const buttons = [
        { icon: icons.collapse, onClick: collapseTree, tooltip: 'Collapse tree' },
        { icon: icons.warning, onClick: () => toggleProblems(buildingId, devices), tooltip: 'Open on problems' },
        { icon: icons.buildingDetails, onClick: () => history.push(`/buildings/${buildingId}`), tooltip: 'Building details' },
        { icon: icons.refresh, onClick: () => refreshBuilding(buildingId), tooltip: 'Refresh info' }
    ];

    return (
        <div className={classes.toolbar}>
            {buttons.map(button => <IconButton key={button.icon} {...button} />)}
        </div>
    );
};

const mapStateToProps = state => ({
    devices: state.devices.models
});

const mapDispatchToProps = {
    collapseTree,
    refreshBuilding,
    toggleProblems
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
