import PanelDivider from 'Components/PanelDivider';
import { actions as deviceActions } from 'ducks/devices';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DeviceDetails from './DeviceDetails';
import WiseBoxDetails from './WiseBoxDetails';

const Component = props => {
    const { childDevices, device, submitting } = props;
    const { fetchOne, remove } = props;

    useEffect(() => {
        if (device.wise_box) {
            fetchOne(device.id);
        }
    }, [device.id]);

    return (
        <>
            <DeviceDetails childDevices={childDevices} remove={remove} submitting={submitting} device={device}/>
            <PanelDivider/>
            <WiseBoxDetails device={device} submitting={submitting}/>
            <PanelDivider/>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        submitting: state.devices.submitting
    };
};

const mapDispatchToProps = ({
    fetchOne: deviceActions.fetchOne,
    remove: deviceActions.remove

});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
