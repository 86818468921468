import { TABS_HEIGHT } from 'utils/ui/constants';

export default () => ({
    tab: {
        fontSize: '0.65rem',
        height: TABS_HEIGHT,
        minHeight: TABS_HEIGHT,
        minWidth: 72
    },
    tabs: {
        height: TABS_HEIGHT,
        minHeight: TABS_HEIGHT
    }
});
