import { isBoolean, isObject, sortBy } from 'lodash';

export default metadata_values => {
    const attributes = [];

    if (metadata_values.length > 0) {
        attributes.push({ label: 'Metadatas', styleLabel: { fontWeight: 'bold' } });
    }

    const metadatas = metadata_values.map(({ value, metadata_name }) => {
        if (isObject(value)) {
            value = JSON.stringify(value);
        }

        if (isBoolean(value)) {
            value = value ? 'Yes' : 'No';
        }

        return { content: value, label: metadata_name };
    });

    return [...attributes, ...sortBy(metadatas, 'label')];
};
