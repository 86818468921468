import TabsToolbar from 'Components/TabsToolbar';
import React from 'react';

const Toolbar = ({ currentTab, device }) => {
    const deviceTabs = [
        { text: 'Details', id: 'details', url: `/devices/${device.id}/details` },
        { text: 'Devices', id: 'devices', url: `/devices/${device.id}/devices` },
        { text: 'Realtime', id: 'realtime', url: `/devices/${device.id}/realtime` },
        { text: 'Logs', id: 'logs', url: `/devices/${device.id}/logs` },
        { text: 'Deploys', id: 'deploys', url: `/devices/${device.id}/deploys` }

    ];

    return (
        <TabsToolbar currentTab={currentTab} tabs={deviceTabs}/>
    );
};

export default Toolbar;
