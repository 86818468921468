import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/readings';
import React from 'react';
import { connect } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { metadatas, model } = props;

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        ...metadataFields(metadatas, model),
        {
            type: 'checkBox',
            label: 'Activate Measurement?',
            name: 'activate_measurement'
        }
    ];

    return (
        <EntityManageView
            formSchema={formSchema}
            formInitialValues={model}
            label="Edit Reading"
            {...props}
        />
    );
};

const mapStateToProps = state => ({
    metadatas: selectorMetadatas.metadataFromReading(state),
    model: state.readings.models[state.drawerView.entityId],
    submitting: state.readings.submitting
});

const mapDispatchToProps = ({
    create: actions.create,
    update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
