const cellContentRenderer = value => value ? 'Yes' : 'No';

export default [
    {
        width: 200,
        flexGrow: 1.0,
        label: 'Email',
        dataKey: 'email'
    },
    {
        width: 200,
        flexGrow: 1.0,
        label: 'Name',
        dataKey: 'name'
    },
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Active?',
        dataKey: 'enabled',
        cellContentRenderer
    },
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Config?',
        dataKey: 'config',
        cellContentRenderer
    }
];
