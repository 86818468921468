import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Component = props => {
    const { classes, disabled, schema, input, label, meta } = props;
    const { allowBlank, formData } = schema;

    let Options = [];
    if (allowBlank) {
        Options.push(
            <MenuItem
                dense
                classes={{ root: classes.menuItemRoot }}
                key=""
                value=""
            >
            </MenuItem>
        );
    }

    formData.forEach(item => {
        Options.push(
            <MenuItem
                dense
                classes={{ root: classes.menuItemRoot }}
                key={item.id}
                value={item.id}
            >
                {item.name}
            </MenuItem>
        );
    });

    let Error;
    if (meta.touched && !!meta.error) {
        Error = (
            <FormHelperText id="helper-text" error>
                {meta.error}
            </FormHelperText>
        );
    }

    return (
        <FormControl
            className={classes.formControl}
            disabled={disabled}
            error={meta.touched && !!meta.error}
            variant="filled"
        >
            <InputLabel
                htmlFor="filled-select"
                shrink={true}
                style={{
                    transform: 'translate(12px, -15px) scale(0.75)'
                }}
            >
                {label}
            </InputLabel>
            <Select
                classes={{ icon: classes.icon }}
                inputProps={input}
                input={
                    <FilledInput
                        id="filled-select"
                        style={{ height: 30, fontSize: '0.65rem', paddingBottom: 16 }}
                    />
                }
            >
                {Options}
            </Select>
            {Error}
        </FormControl>
    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.object, //injected by Field from Redux forms to control value and callbacks
    label: PropTypes.string,
    meta: PropTypes.object //injected by Field from Redux forms to access interaction state
};
