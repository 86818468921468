import SimpleList from 'Components/SimpleList';
import BuildingsList from 'Components/SimpleList/Buildings';
import { uniqBy } from 'lodash';
import React from 'react';
import { unixToDate } from 'utils/date';
import displayMetadata from 'utils/displayMetadata';
import getUserName from 'utils/getUserName';

const generateEquipmentProperties = equipment => {
    const
        buildings = uniqBy(equipment.devices?.map(({ building_id, building_name }) => ({ id: building_id, name: building_name })), 'id') || [],
        organizations = uniqBy(equipment.devices?.map(({ organization_id, organization_name }) => ({ id: organization_id, name: organization_name })), 'id') || [];

    return [
        { label: 'ID', content: equipment.id },
        { label: 'Name', content: equipment.name },
        { label: 'Description', content: equipment.description },
        { label: 'Usable?', content: equipment.usable ? 'Yes' : 'No' },
        { label: 'Equipment category', content: equipment.equipment_category_name },
        { label: 'Equipment type', content: equipment.equipment_type_name },
        { label: 'Input protocol', content: equipment.in_protocol_name },
        { label: 'Output protocol', content: equipment.out_protocol_name },
        { label: `Buildings (${buildings.length})`, content: <BuildingsList rows={buildings} route="buildings"/> },
        { label: `Organizations (${organizations.length})`, content: organizations.map(({ name }) => name).sort().join(', ') },
        { label: 'Device Count', content: equipment.devices_count },
        { label: 'Creation', content: `${getUserName(equipment.created_by)} @ ${unixToDate(equipment.created_at)}` },
        { label: 'Update', content: `${getUserName(equipment.updated_by)} @ ${unixToDate(equipment.updated_at)}` }
    ];
};

const Details = props => {
    const { model } = props;

    const content = [
        ...generateEquipmentProperties(model),
        ...displayMetadata(model.metadata_values)
    ];

    return (
        <SimpleList content={content}/>
    );
};

export default Details;
