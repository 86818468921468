import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/organizations';
import React from 'react';
import { connect } from 'react-redux';
import validators from 'utils/forms/validators';

const formSchema = [
    {
        type: 'text',
        label: 'Name\x2a',
        name: 'name',
        validate: [validators.required]
    }
];

const Component = props => {
    const { model, submitting } = props;
    const { update } = props;

    return (
        <EntityManageView
            formSchema={formSchema}
            formInitialValues={model}
            label="Edit Organization"
            submitting={submitting}
            update={update}
        />
    );
};

const mapStateToProps = state => ({
    model: state.organizations.models[state.drawerView.entityId],
    submitting: state.users.submitting
});

const mapDispatchToProps = ({
    update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
