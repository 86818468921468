import EntityView from 'Components/EntityView';
import { actions } from 'ducks/metadata';
import React from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const properties = model => [
    { label: 'ID', content: model.id },
    { label: 'Name', content: model.name },
    { label: 'Metadata type', content: model.metadata_type },
    { label: 'Enumerator members', content: (model.enumerator_members || []).join(', ') },
    { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props => (
    <EntityView
        disableDelete
        disableEdit
        properties={properties}
        tabsHeader={'Metadata details'}
        {...props}
    />
);

const mapStateToProps = state => ({
    loading: state.metadatas.loading,
    model: state.metadatas.models[state.drawerView.entityId],
    root: state.users.models[state.auth.currentUserId].root
});

const mapDispatchToProps = ({
    remove: actions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
