import Table from 'Components/SimpleTable';
import { isBoolean, isObject, sortBy } from 'lodash';
import React from 'react';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const generateColumns = readings => {
    const properties = [
        { label: 'Name', dataKey: 'name' },
        { label: 'Activate Measurement?', dataKey: 'activate_measurement', transform: model => model.activate_measurement ? 'Yes' : 'No' }
    ];

    const dataKeys = new Set();
    readings.forEach(reading => {
        reading.metadata_values.forEach(metadata_value => {
            const dataKey = metadata_value.metadata_id;

            if (!dataKeys.has(dataKey)) {
                properties.push({ label: metadata_value.name, dataKey });
                dataKeys.add(dataKey);
            }
        });
    });

    properties.push(
        { label: 'Usage Count', dataKey: 'measurements_count' },
        { label: 'Update', dataKey: 'updated_at', transform: model => `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
    );

    return properties;
};

const Readings = props => {
    const { onRowClick, readings } = props;

    const columns = generateColumns(readings);

    let rows = readings.map(reading => {
        const object = { ...reading };

        reading.metadata_values.forEach(metadata_value => {
            let value = metadata_value.value;

            if (isObject(value)) {
                value = JSON.stringify(value);
            }

            if (isBoolean(value)) {
                value = value ? 'Yes' : 'No';
            }

            object[metadata_value.name] = value;
            object[metadata_value.metadata_id] = value;
        });

        return object;
    });

    rows = sortBy(rows, 'Modbus Memory Address', 'MBus Port Id');

    return (
        <Table columns={columns} onRowClick={onRowClick} rows={rows} rowsPerPage={100}/>
    );
};

export default Readings;
