import React from 'react';

export default description => {
    if (!description) {
        return '-';
    }

    return description.split('\n').map((line, index) => {
        if (!line) {
            return;
        }

        return (
            <React.Fragment key={index}>
                {line}
                <br/>
            </React.Fragment>
        );
    });
};
