import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/users';
import { sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import validators from 'utils/forms/validators';

const Component = props => {
    const { currentUser, model, submitting } = props;
    const { update } = props;

    let formSchema = [];

    if (currentUser.id === model.id || currentUser.superuser) {
        formSchema.push(
            {
                type: 'text',
                label: 'Name\x2a',
                name: 'name',
                validate: [validators.required]
            },
            {
                type: 'text',
                label: 'Email\x2a',
                name: 'email',
                validate: [validators.required]
            },
            {
                type: 'password',
                label: 'Password',
                name: 'password'
            },
            {
                type: 'password',
                label: 'Password Confirmation',
                name: 'password_confirmation'
            }
        );
    }

    if (currentUser.superuser) {
        let organizations = sortBy(Object.values(props.organizations), 'name');

        formSchema.push(
            {
                formData: organizations,
                label: 'Organizations\x2a',
                name: 'organization_ids',
                type: 'selectMultiple',
                validate: [validators.required]
            },
            {
                type: 'checkBox',
                label: 'Administrator?',
                name: 'administrator'
            },
            {
                type: 'checkBox',
                label: 'Superuser?',
                name: 'superuser'
            },
            {
                type: 'checkBox',
                label: 'Enabled?',
                name: 'enabled'
            }
        );
    }

    return (
        <EntityManageView
            formSchema={formSchema}
            formInitialValues={model}
            label="Edit User"
            submitting={submitting}
            update={update}
        />
    );
};

const mapStateToProps = state => ({
    currentUser: state.users.models[state.auth.currentUserId],
    model: state.users.models[state.drawerView.entityId],
    organizations: state.organizations.models,
    submitting: state.users.submitting
});

const mapDispatchToProps = ({
    update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
