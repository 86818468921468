import { lighten } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
    icon: {
        color: theme.palette.primary.white
    },
    item: {
        color: theme.palette.primary.white,
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.2)
        }
    },
    menu: {
        background: theme.palette.primary.main
    },
    paper: {
        background: theme.palette.primary.main,
        width: 300
    }
});
