import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/wise_boxes';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import columns from 'utils/columns/wise_boxes';
import ClaimWiseBox from './Claim';

const Component = props => {
    const { startPooling, stopPooling, ...rest } = props;

    useEffect(() => {
        startPooling(5);

        return stopPooling;
    }, []);

    return (
        <EntitiesView
            columns={columns}
            showComponent={ClaimWiseBox}
            {...rest}
        />
    );
};

const mapStateToProps = (
    {
        wise_boxes: { allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection },
        drawerView: { entity: entityType }
    }) =>
    ({
        allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection,
        entityType
    });

const { changeSearch, changeSort, fetchAll, startPooling, stopPooling, toggleAll, toggleOne } = actions;

const mapDispatchToProps = ({
    changeSearch,
    changeSort,
    fetchData: fetchAll,
    startPooling,
    stopPooling,
    toggleAll,
    toggleOne: event => {
        event.stopPropagation();
        return toggleOne(event.target.name);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);

