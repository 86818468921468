export default (columns = []) => {
    const
        searchableKeys = columns.filter(column => column.searchable !== false).map(column => column.dataKey),
        sortColumn = columns.find(column => column.sortDirection),
        sortBy = sortColumn && sortColumn.dataKey || searchableKeys[0],
        sortDirection = sortColumn && sortColumn.sortDirection || 'ASC';

    return {
        allChecked: false,
        checked: new Set,
        ids: [],
        models: {},
        loaded: false,
        loading: false,
        errorMessage: '',
        searching: false,
        searchValue: '',
        sortBy,
        sortDirection,
        searchableKeys,
        submitting: false
    };
};
