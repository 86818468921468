import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import SimpleList from 'Components/SimpleList';
import { actions as deviceActions } from 'ducks/devices';
import ShowEquipment from 'Pages/Equipment/Show';
import EditDevice from 'Pages/Tree/Device/Edit';
import ReplaceDevice from 'Pages/Tree/Device/ReplaceDevice';
// TODO remove:template  import CreateTemplate from 'Pages/Tree/Device/WiseBox/CreateTemplate';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import displayMetadata from 'utils/displayMetadata';
import formatDescription from 'utils/formatDescription';
import getUserName from 'utils/getUserName';
import statusToComponent from 'utils/statusToComponent';
import icons from 'utils/ui/icons';

const baseProperties = [
    { label: 'ID', content: model => model.id },
    { label: 'Description', content: model => formatDescription(model.description) },
    { label: 'Name', content: model => model.name },
    { label: 'Status', content: model => statusToComponent(model.status) },
    { label: 'Creation', content: model => `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: model => `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const generateAttributes = (model, baseAttributes, setOpenDrawer) => {
    const attributes = baseAttributes.map(({ label, content, styleLabel }) => ({
        label, content: content(model), styleLabel
    }));

    attributes.push({
        label: 'Equipment',
        content: (
            <a href="#" onClick={event => {
                event.preventDefault();
                setOpenDrawer('showEquipment');
            }}>
                {model.equipment_name}
            </a>
        )
    });

    if (model.wise_box) {
        attributes.push(
            { label: 'MAC Address', content: model.mac_address },
            { label: 'Firmware Version', content: model.firmware_version }
        );
    }

    return [...attributes, ...displayMetadata(model.metadata_values)];
};

const Component = props => {
    const { childDevices, device, measurements, submitting } = props;
    const { remove, replace } = props;

    const [drawer, setOpenDrawer] = useState(null);
    const closeDrawer = () => setOpenDrawer(null);

    const deviceAttributes = generateAttributes(device, baseProperties, setOpenDrawer);

    const
        hasChildDevices = !!childDevices.length,
        hasActiveIndicators = !!measurements.find(m => m.indicator_id && !m.indicator_stop);

    const buttons = [
        {
            icon: icons.edit,
            onClick: () => setOpenDrawer('editDevice'),
            tooltip: 'Edit'
        }
    ];

    if (!hasActiveIndicators && !hasChildDevices) {
        buttons.push(
            {
                confirmationHeader: 'Delete Confirmation',
                confirmationText: 'Are you sure you want to delete?',
                submitting: submitting,
                icon: icons.trash,
                onClick: () => remove(device.id, `/buildings/${device.building_id}`),
                tooltip: 'Delete'
            }
        );
    }

    if (device.out_protocol_name === 'Reading Metal') {
        if (hasActiveIndicators) {
            buttons.push({
                icon: icons.replace,
                onClick: () => setOpenDrawer('replaceDevice'),
                tooltip: 'Replace device'
            });
        } else {
            buttons.push({
                confirmationHeader: 'Replace Confirmation',
                confirmationText: 'Are you sure you want to mark device as replaced?',
                submitting: submitting,
                icon: icons.replace,
                onClick: () => replace(device.id),
                tooltip: 'Mark device as replaced'
            });
        }
    }

    let DrawerContent;
    // TODO remove:template
    // if (drawer === 'createTemplate') {
    //		DrawerContent = (
    //				<CreateTemplate id={device.id} closeDrawer={closeDrawer}/>
    //		);
    //} else
    if (drawer === 'editDevice') {
        DrawerContent = (
            <EditDevice id={device.id} closeDrawer={closeDrawer}/>
        );
    } else if (drawer === 'replaceDevice') {
        DrawerContent = (
            <ReplaceDevice id={device.id} onSave={closeDrawer}/>
        );
    } else if (drawer === 'showEquipment') {
        DrawerContent = (
            <ShowEquipment disableButtons id={device.equipment_id}/>
        );
    }

    return (
        <React.Fragment>
            <Panel buttons={buttons}>
                <SimpleList content={deviceAttributes}/>
            </Panel>
            <Drawer onClose={closeDrawer} open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    submitting: state.devices.submitting
});

const mapDispatchToProps = ({
    replace: deviceActions.replace,
    remove: deviceActions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
