import Drawer from '@material-ui/core/Drawer';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { HEADER_HEIGHT } from 'utils/ui/constants';

const styles = {
    drawerContent: {
        top: 64,
        width: '60vw',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
    },
    bigDrawerContent: {
        top: 64,
        width: '90vw',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
    }
};

const Component = props => {
    const { children, classes, open = true } = props;
    const { onClose } = props;

    return (
        <Drawer
            anchor="right"
            classes={{ paper: props.big ? classes.bigDrawerContent : classes.drawerContent }}
            onClose={onClose}
            open={open}
        >
            {children}
        </Drawer>
    );
};

Component.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};

export default withStyles(styles)(Component);
