import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/equipment';
import EditEquipment from 'Pages/Equipment/Edit';
import NewEquipment from 'Pages/Equipment/New';
import ShowEquipment from 'Pages/Equipment/Show';
import EditReading from 'Pages/Readings/Edit';
import NewReading from 'Pages/Readings/New';
import ShowReading from 'Pages/Readings/Show';
import React from 'react';
import { connect } from 'react-redux';
import columns from 'utils/columns/equipments';

const Component = props => {
    const { entityType } = props;

    let editComponent = EditEquipment;
    let newComponent = NewEquipment;
    let showComponent = ShowEquipment;

    if (entityType === 'READING') {
        editComponent = EditReading;
        newComponent = NewReading;
        showComponent = ShowReading;
    }

    return (
        <EntitiesView
            big
            columns={columns}
            editComponent={editComponent}
            newComponent={newComponent}
            showComponent={showComponent}
            {...props}
        />
    );
};

const mapStateToProps = (
    {
        equipments: { allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection },
        drawerView: { entity: entityType }
    }) =>
    ({
        allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection,
        entityType
    });

const { changeSearch, changeSort, fetchAll, toggleAll, toggleOne } = actions;

const mapDispatchToProps = ({
    changeSearch,
    changeSort,
    fetchData: fetchAll,
    toggleAll,
    toggleOne: event => {
        event.stopPropagation();
        return toggleOne(event.target.name);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);

