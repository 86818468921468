export default theme => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '20% minmax(300px, 450px ) 20%',
        gridTemplateRows: '20% minmax(350px, 450px ) 20%',
        justifyContent: 'space-around',
        alignContent: 'space-around',
        width: '100vw',
        height: '100vh',
        minWidth: '400px',
        minHeight: '450px',
        backgroundColor: theme.palette.primary.main,
        alignItems: 'center'
    },
    loginArea: {
        //gridArea: 'space2',
        gridColumn: '2 ',
        gridRow: '2'
    },
    margin: {
        margin: theme.spacing.unit * 2
    },
    padding: {
        padding: theme.spacing.unit
    },
    textTransformationNone: {
        textTransform: 'none',
        textDecoration: 'none'
    }
});
