import theme from '~/theme';

export default () => ({
    root: {
        boxShadow: 'none',
        marginTop: 5,
        width: '100%'
    },
    tableRowHeader: {
        height: '38px'
    },
    tableRow: {
        height: '30px',
        '&:hover': {
            background: '#EEEEEE'
        }
    },
    tableCellHeader: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        paddingLeft: 5
    },
    tableCell: {
        fontSize: '0.65rem',
        padding: 0,
        paddingLeft: 5
    },
    table: {
        minWidth: 500
    },
    tableWrapper: {
        overflowX: 'auto'
    }
});
