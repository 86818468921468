import EntityView from 'Components/EntityView';
import { actions } from 'ducks/readings';
import React from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import displayMetadata from 'utils/displayMetadata';
import getUserName from 'utils/getUserName';

const properties = model => [
    { label: 'ID', content: model.id },
    { label: 'Name', content: model.name },
    { label: 'Activate Measurement?', content: model.activate_measurement ? 'Yes' : 'No' },
    { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const allProperties = model => [...properties(model), ...displayMetadata(model.metadata_values)];

const Component = props => (
    <EntityView
        entityType={'READING'}
        tabsHeader="Reading properties"
        properties={allProperties}
        {...props}
    />
);

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.id || state.drawerView.entityId;
    return {
        id,
        loading: state.readings.loading,
        model: state.readings.models[id]
    };
};

const mapDispatchToProps = ({
    remove: actions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
