import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Panel from 'Components/Panel';
import styles from 'Pages/Equipment/Show/styles';
import React from 'react';
import { connect } from 'react-redux';
import Details from './Details';
import Toolbar from './Toolbar';

const Component = props => {
    const { classes, model } = props;
    return (
        <Grid item className={classes.mainContainer}>
            <Grid container className={classes.headerContainer}>
                <Toolbar organization={model}/>
            </Grid>
            <Grid item className={classes.bottomContainer}>
                <Panel id="properties" header="Details">
                    <Details model={model}/>
                </Panel>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = state => ({
    loading: state.users.loading, model: state.organizations.models[state.drawerView.entityId]
});

export default connect(mapStateToProps)(withStyles(styles)(Component));
