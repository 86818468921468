import { sagas as authentication } from 'ducks/authentication';
import { sagas as bootstrap } from 'ducks/bootstrap';
import { sagas as buildings } from 'ducks/buildings';
import { sagas as deploys } from 'ducks/deploys';
import { sagas as deviceLogs } from 'ducks/deviceLogs';
import { sagas as devices } from 'ducks/devices';
import { sagas as equipment } from 'ducks/equipment';
import { sagas as equipmentCategories } from 'ducks/equipmentCategories';
import { sagas as loadBuilding } from 'ducks/loadBuilding';
import { sagas as logs } from 'ducks/logs';
import { sagas as measurements } from 'ducks/measurements';
import { sagas as metadata } from 'ducks/metadata';
import { sagas as metadataRules } from 'ducks/metadataRules';
import { sagas as organizations } from 'ducks/organizations';
import { sagas as protocols } from 'ducks/protocols';
import { sagas as readings } from 'ducks/readings';
import { sagas as users } from 'ducks/users';
import { sagas as wise_boxes } from 'ducks/wise_boxes';
import { all, fork } from 'redux-saga/effects';

export default function* rootSaga() {
    try {
        yield all(
            [
                ...authentication,
                ...bootstrap,
                ...buildings,
                ...deploys,
                ...deviceLogs,
                ...devices,
                ...equipment,
                ...equipmentCategories,
                ...loadBuilding,
                ...logs,
                ...measurements,
                ...metadata,
                ...metadataRules,
                ...organizations,
                ...protocols,
                ...readings,
                // TODO remove:template ...templates,
                ...users,
                ...wise_boxes
            ].map(fork)
        );
    } catch (error) {
        console.error(error);
    }
}
