import { fade } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    text: {
        color: fade(theme.palette.primary.dark, 0.75),
        fontWeight: 'bold',
        lineHeight: '36px',
        fontSize: '0.65rem'
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4
    },
    iconButton: {
        fontSize: 20,
        color: fade(theme.palette.primary.dark, 0.75)
    },
    listItem: {
        minHeight: 40,
        paddingTop: 0,
        paddingBottom: 0,
        '&:nth-of-type(2n + 1)': {
            background: '#EDF3FE'
        },
        borderBottom: '1px solid #DDDDDD'
    },
    listItemIcon: {
        marginRight: 0
    },
    listItemIconDown: {
        color: theme.palette.down.main
    },
    listItemIconWarning: {
        color: theme.palette.warning.main
    },
    listItemText: {
        paddingLeft: 0,
        paddingRight: 0
    }
});
