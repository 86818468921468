export default () => ({
    formControl: {
        minWidth: '100%',
        marginTop: 16,
        marginBottom: 8
    },
    icon: {
        marginTop: 6
    },
    menuItemRoot: {
        fontSize: '0.65rem',
        paddingTop: 2,
        paddingBottom: 2
    }
});
