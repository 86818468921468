import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { actions as wiseBoxActions } from 'ducks/wise_boxes';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import selectorForm from 'selectors/form';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { availableDevices, formValues, loading, submitting, wise_box } = props;
    const { claim, fetchAvailable, onClose } = props;

    useEffect(() => {
        fetchAvailable();
    }, []);

    let
        buildings = Object.values(props.buildings),
        organizations = Object.values(props.organizations);

    let
        devicesOrganizationIds = [...new Set(availableDevices.map(b => b.organization_id))];

    const availableOrganizations = organizations.filter(organization => {
        return devicesOrganizationIds.includes(organization.id);
    });

    if (!availableOrganizations.length) {
        availableOrganizations.push({ id: '', name: 'No organization with WiseBox device available' });
    }

    const formSchema = [
        {
            disabled: !!formValues.building_id || loading,
            formData: sortBy(availableOrganizations, 'name'),
            label: 'Organization\x2a',
            name: 'organization_id',
            type: 'select',
            validate: [validators.required]
        }
    ];

    const getName = device => {
        return (
            buildings.find(building => building.id === device.building_id).name + ' > ' + device.name
        );
    };

    if (formValues.organization_id) {
        const deviceData = availableDevices
        .filter(device => device.organization_id === formValues.organization_id)
        .map((device) => ({
            id: device.id,
            name: getName(device)
        }));

        formSchema.push({
            type: 'select',
            label: 'Root Device\x2a',
            name: 'device_id',
            validate: [validators.required],
            formData: sortBy(deviceData, 'name')
        });
    }

    const onSubmit = values => {
        const data = serializeForm(values, formSchema);
        claim(wise_box.id, data, () => onClose());
    };

    return (
        <FormViewer label={'Claim WiseBox'}>
            <Form
                formSchema={formSchema}
                isSubmitting={submitting || loading}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = state => ({
    availableDevices: state.devices.available,
    buildings: state.buildings.models,
    formValues: selectorForm.getFormValues(state),
    organizations: state.organizations.models,
    submitting: state.wise_boxes.submitting,
    wise_box: state.wise_boxes.models[state.drawerView.entityId],
    loading: state.devices.loading
});

const mapDispatchToProps = ({
    claim: wiseBoxActions.claim,
    fetchAvailable: deviceActions.fetchAvailable
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
