import PrivateRoute from 'Components/PrivateRoute';
import BuildingsPage from 'Pages/Buildings/Table';
import EquipmentTable from 'Pages/Equipment/Table';
import EquipmentCategoriesTable from 'Pages/EquipmentCategories/Table';
import MapPage from 'Pages/Map';
import MetadataTable from 'Pages/Metadata/Table';
import MetadataRulesTable from 'Pages/MetadataRules/Table';
import OrganizationsTable from 'Pages/Organizations/Table';
import Profile from 'Pages/Profile';
import TreePage from 'Pages/Tree';
import UsersTable from 'Pages/Users/Table';
import WiseBoxesTable from 'Pages/WiseBoxes/Table';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

const Routes = () => (
    <Switch>
        <PrivateRoute exact path="/map" component={MapPage}/>
        <PrivateRoute exact path="/buildings" component={BuildingsPage}/>

        <PrivateRoute exact path="/profile" component={Profile}/>

        <PrivateRoute exact path="/buildings/:buildingId/:section?" component={TreePage}/>
        <PrivateRoute exact path="/devices/:deviceId/:section?" component={TreePage}/>

        <PrivateRoute exact path="/equipment" component={EquipmentTable}/>
        <PrivateRoute exact path="/equipment_categories" component={EquipmentCategoriesTable}/>
        <PrivateRoute exact path="/metadatas" component={MetadataTable}/>
        <PrivateRoute exact path="/metadata_rules" component={MetadataRulesTable}/>
        <PrivateRoute exact path="/organizations" component={OrganizationsTable}/>

        <PrivateRoute exact path="/unclaimed" component={WiseBoxesTable}/>
        <PrivateRoute exact path="/users" component={UsersTable}/>

        <Redirect exact from="/" to="/map"/>
        <Redirect to="/map"/>
    </Switch>
);

export default Routes;
