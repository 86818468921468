import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Component = props => {
    const { classes, disabled, input, label, meta, ...rest } = props;

    // TODO Check if number or if moment.format is the correct one
    if (typeof input.value !== 'string') {
        input.value = moment.unix(input.value).format('YYYY-MM-DDTHH:mm');
    }

    const onKeyDown = event => event.preventDefault();

    return (
        <TextField
            {...rest}
            className={classes.textField}
            disabled={disabled}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            id="datetime-local"
            onKeyDown={onKeyDown}
            // inputProps={input}
            // InputLabelProps={{ shrink: true }}
            label={label}
            type="datetime-local"
            variant="filled"

            inputProps={{
                ...input,
                style: {
                    fontSize: '0.65rem'
                }
            }}
            InputProps={{
                style: {
                    borderRadius: 0,
                    height: 30,
                    paddingBottom: 15
                }
            }}
            InputLabelProps={{
                shrink: true,
                style: {
                    transform: 'translate(12px, -15px) scale(0.75)'
                }
            }}

        />
    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.object, //injected by Field from Redux forms to control value and callbacks
    label: PropTypes.string,
    meta: PropTypes.object //injected by Field from Redux forms to access interaction state
};
