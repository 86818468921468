import Drawer from 'Components/Drawer';
import PropTypes from 'prop-types';
import React from 'react';

const Component = props => {
    const { big, context, editComponent: EditComponent, newComponent: NewComponent, showComponent: ShowComponent } = props;
    const { onClose } = props;

    if (!context) {
        return null;
    }

    if (context === 'show') {
        return (
            <Drawer big={big} onClose={onClose}>
                <ShowComponent onClose={onClose}/>
            </Drawer>
        );
    } else if (context === 'new') {
        return (
            <Drawer big={big} onClose={onClose}>
                <NewComponent/>
            </Drawer>
        );
    } else if (context === 'edit') {
        return (
            <Drawer big={big} onClose={onClose}>
                <EditComponent/>
            </Drawer>
        );
    }

    return null;
};

Component.propTypes = {
    context: PropTypes.string,
    showComponent: PropTypes.elementType,
    newComponent: PropTypes.elementType,
    editComponent: PropTypes.elementType,
    onClose: PropTypes.func.isRequired
};

export default Component;
