import { put, takeLatest } from 'redux-saga/effects';
import columns from 'utils/columns/users';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';

import actionsFactory from 'utils/factories/actions';
import { uploadFiles } from 'utils/request';
import { handleError } from 'utils/sagasHelpers';
import { FETCH_BUILDINGS, FULFILLED, ORGANIZATION, REJECTED, UPLOAD_JSON } from './actionTypes';

// actions
const actions = actionsFactory(ORGANIZATION);

actions.uploadJson = (id, file, callback) => ({
    meta: callback,
    payload: { id, file },
    type: UPLOAD_JSON
});

export { actions };

// reducer
const initialState = initialStateFactory(columns);
const reducer = reducerFactory(ORGANIZATION);
export default (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case UPLOAD_JSON:
            return reducer({ ...state, uploadingJson: true }, action);

        case UPLOAD_JSON + FULFILLED:
        case UPLOAD_JSON + REJECTED:
            return reducer({ ...state, uploadingJson: false }, action);

        default:
            return reducer(state, action);
    }
};

// sagas
const sagas = sagasFactory(ORGANIZATION, '/organizations');

function* watchUploadJson() {
    yield takeLatest(UPLOAD_JSON, function* ({ payload: { id, file }, meta: callback }) {
        try {
            yield uploadFiles(`/organizations/${id}/upload_json`, 'POST', file);
            yield put({ type: UPLOAD_JSON + FULFILLED });
            yield put({ type: FETCH_BUILDINGS });
            callback();
        } catch (error) {
            const errorObject = handleError(UPLOAD_JSON, error);
            yield put(errorObject);
        }
    });
}

sagas.push(
    watchUploadJson
);

export { sagas };
