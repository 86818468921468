import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as measurementActions } from 'ducks/measurements';
import React from 'react';
import { connect } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { device, metadatas, readingId, submitting } = props;
    const { create, closeDrawer } = props;

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },

        ...metadataFields(metadatas)
    ];

    const onSubmit = formValues => {
        let data = {
            ...serializeForm(formValues, formSchema),
            device_id: device.id,
            reading_id: readingId
        };

        create(data, closeDrawer);
    };

    return (
        <FormViewer label="Add Measurement">
            <Form
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = (state, ownProps) => ({
    metadatas: selectorMetadatas.metadataFromMeasurement(state, ownProps),
    submitting: state.measurements.submitting
});

const mapDispatchToProps = ({
    create: measurementActions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
