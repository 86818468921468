import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Component = props => {
    const { accept, classes, disabled, input, label, meta } = props;

    let Errors;
    if (meta.touched && !!meta.error) {
        Errors = (
            <FormHelperText error id="helper-text">
                {meta.error}
            </FormHelperText>
        );
    }
    return (
        <FormControl
            disabled={disabled}
            error={meta.touched && !!meta.error}
            className={classes.input}
            variant="filled"
        >
            <InputLabel
                shrink={true}
                style={{
                    transform: 'translate(12px, -15px) scale(0.75)'
                }}
            >
                {label}
            </InputLabel>
            <Input
                inputProps={{ accept }}
                onChange={event => input.onChange(event.target.files[0])}
                style={{ marginTop: 0 }}
                type="file"
            />
            {Errors}
        </FormControl>
    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.object, //injected by Field from Redux forms to control value and callbacks
    label: PropTypes.string,
    meta: PropTypes.object //injected by Field from Redux forms to access interaction state
};
