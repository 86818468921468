import Panel from 'Components/Panel';
import SimpleTable from 'Components/SimpleTable';
import { sortBy } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import getUserName from 'utils/getUserName';
import icons from 'utils/ui/icons';
import globals from '~/globals';

const generateCurrentConfiguration = deploy => (
    <Link
        onClick={event => {
            event.preventDefault();
            window.open(`${globals.apiUrl}/deploys/${deploy.id}/configuration`);
        }}
        to="/"
    >
        show
    </Link>
);

const columns = [
    { label: 'Configuration', dataKey: 'configuration', transform: generateCurrentConfiguration },
    { label: 'MD5', dataKey: 'md5' },
    { label: 'Deplyed At', dataKey: 'created_at' },
    { label: 'Deployed By', dataKey: 'deployed_by', transform: deploy => getUserName(deploy.deployed_by) }
];

const Table = props => {
    const { device, deploys, submitting, syncable } = props;
    const { deploy } = props;

    const buttons = [
        {
            confirmationHeader: 'Operation Confirmation',
            confirmationText: 'Are you sure you want to deploy this configuration?',
            disabled: !syncable,
            icon: icons.upload,
            onClick: () => deploy(device.id),
            submitting: submitting,
            tooltip: 'Deploy configuration'
        }
    ];

    const rows = sortBy(Object.values(deploys).filter(deploy => deploy.device_id === device.id), 'created_at').reverse();

    return (
        <Panel buttons={buttons}>
            <SimpleTable columns={columns} rows={rows}/>
        </Panel>
    );
};

export default Table;
