import columns from 'utils/columns/metadataRules';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';

import actionsFactory from 'utils/factories/actions';
import { METADATA_RULE } from './actionTypes';

// actions
const actions = actionsFactory(METADATA_RULE);
export { actions };

// reducer
const initialState = initialStateFactory(columns);
const reducer = reducerFactory(METADATA_RULE);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(METADATA_RULE, '/metadata_rules');
export { sagas };
