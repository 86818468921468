export default () => ({
    details: {
        padding: 20,
        paddingTop: 10
    },
    detailsHeader: {
        padding: 20,
        paddingTop: 0
    },
    header: {
        backgroundColor: '#EEEEEE',
        fontSize: '80%',
        fontWeight: 'bold',
        padding: '15px 0 15px 25px'
    },
    paper: {
        borderRadius: 0
    },
    toolbar: {
        backgroundColor: '#EEEEEE',
        padding: 10
    }
});
