import { SPACER_MINI, TREE_HEADER_HEIGHT } from 'utils/ui/constants';

export default () => ({
    treeCollapsed: {
        width: '100%',
        height: TREE_HEADER_HEIGHT + SPACER_MINI
    },
    treeExpanded: {
        width: '100%'
    },
    expansionPanelDetails: {
        borderTop: '1px solid #DDDDDD',
        height: 'calc(100vh - 140px)',
        overflow: 'auto',
        padding: 0
    },
    rounded: {
        '&:first-child': {
            borderRadius: 0
        },
        '&:last-child': {
            borderRadius: 0
        }
    },
    expansionSummary: {
        height: 36,
        margin: 0,
        padding: 0,
        minHeight: 36,
        '&$expansionSummaryExpanded': {
            minHeight: 36,
            margin: 0,
            padding: 0
        }
    },
    expansionSummaryExpanded: {},
    expansionSummaryContent: {
        margin: 0,
        height: TREE_HEADER_HEIGHT,
        minHeight: TREE_HEADER_HEIGHT,
        '&:last-child': {
            padding: 0
        }
    },
    toolbar: {
        lineHeight: '34px'
    },
    trees: {
        width: '100%'
    }
});
