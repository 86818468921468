import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/buildings';
import { sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import validators from 'utils/forms/validators';

const Component = props => {
    const organizations = sortBy(Object.values(props.organizations), 'name');

    const formSchema = [
        {
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        },
        {
            formData: organizations,
            label: 'Organization\x2a',
            name: 'organization_id',
            type: 'select',
            validate: [validators.required]
        },
        {
            label: 'Latitude\x2a',
            name: 'latitude',
            type: 'text',
            validate: [validators.required]
        },
        {
            label: 'Longitude\x2a',
            name: 'longitude',
            type: 'text',
            validate: [validators.required]
        }
    ];

    return (
        <EntityManageView
            formSchema={formSchema}
            label="Create Building"
            {...props}
        />
    );
};

const mapStateToProps = state => ({
    organizations: state.organizations.models,
    submitting: state.buildings.submitting
});

const mapDispatchToProps = ({
    create: actions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
