// actions
import { INITIALIZE_FORM, INITIALIZE_FORM_CLEAN_UP } from './actionTypes';

export const initializeForm = data => {
    return {
        type: INITIALIZE_FORM,
        payload: data
    };
};

export const initializeFormCleanUp = () => ({
    type: INITIALIZE_FORM_CLEAN_UP
});

// reducer
const formInitializersReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case INITIALIZE_FORM:
            return { ...payload };

        case INITIALIZE_FORM_CLEAN_UP:
            return {};

        default:
            return state;
    }
};

export default formInitializersReducer;
