import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/styles/withStyles';
import Icon from 'Components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import icons from 'utils/ui/icons';
import styles from './styles';

const Component = props => {
    const { classes, disabled, input, label } = props;

    return (
        <FormControlLabel
            classes={{ label: classes.label }}
            control={
                <Checkbox
                    color="primary"
                    disabled={disabled}
                    checked={input.value}
                    checkedIcon={<Icon icon={icons.checkSquare} size="sm"/>}
                    fontSize="small"
                    inputProps={input}
                    icon={<Icon icon={icons.square} size="sm"/>}
                />
            }
            label={label}
        />
    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    input: PropTypes.object, //injected by Field from Redux forms to control value and callbacks
    label: PropTypes.string,
    meta: PropTypes.object //injected by Field from Redux forms to access interaction state
};
