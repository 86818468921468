import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { actions as measurementActions } from 'ducks/measurements';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { device, metadatas, submitting } = props;
    const { closeDrawer, fetchMeasurements, update } = props;

    let formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        {
            type: 'textArea',
            label: 'Description',
            name: 'description',
            rows: 8
        }
    ];

    if (!device.has_mqtt_connection && device.equipment_category_name === 'WiseBox') {
        formSchema.push({
            type: 'text',
            label: 'MQTT String Connection - it can only be setted once',
            name: 'mqtt_connection'
        });
    }

    formSchema.push(...metadataFields(metadatas, device));

    useEffect(() => {
        props.fetchDevice(device.id);
    }, []);

    const onSubmit = values => update(
        device.id,
        serializeForm(values, formSchema, device),
        () => {
            fetchMeasurements({ device_id: device.id });
            closeDrawer();
        }
    );

    return (
        <FormViewer label="Edit Device">
            <Form
                formSchema={formSchema}
                formInitialValues={device}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = (state, ownProps) => ({
    device: state.devices.models[ownProps.id],
    metadatas: selectorMetadatas.metadataFromDevice(state, ownProps),
    submitting: state.devices.submitting
});

const mapDispatchToProps = ({
    fetchDevice: deviceActions.fetchOne,
    fetchMeasurements: measurementActions.fetchAll,
    update: deviceActions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
