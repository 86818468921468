import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Marker from 'Components/Maps/Marker';
import PropTypes from 'prop-types';
import React from 'react';
import generateMarker from 'utils/generateMarker';
import history from '~/history';
import theme from '~/theme';
import styles from './styles';

let DevicesTable = ({ classes, gateways }) => (
    <Paper className={classes.tablePaper}>
        <Table className={classes.tableGlobal} padding="dense">
            <TableHead>
                <TableRow classes={{ root: classes.tableRow }}>
                    <TableCell>Device name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Device type</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {gateways.map(dev => (
                    <TableRow key={dev.id} classes={{ root: classes.tableRow }}>
                        <TableCell classes={{ root: classes.tableCell }}>{dev.name}</TableCell>
                        <TableCell classes={{ root: classes.tableCell }}>{dev.status}</TableCell>
                        <TableCell classes={{ root: classes.tableCell }}>Wisebox</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Paper>
);

DevicesTable = withStyles(styles)(DevicesTable);

const mapStatusToIcon = status => {
    const { down, ignore, ok, problem, unknown } = theme.palette.pins;

    const icons = {
        down: generateMarker(down),
        ignore: generateMarker(ignore),
        ok: generateMarker(ok),
        problem: generateMarker(problem),
        unknown: generateMarker(unknown)
    };

    return icons[status];
};

const getTooltip = (classes, building) => {
    return (
        <div>
            <Typography className={classes.popover} classes={{ root: classes.root }}>{building.name}</Typography>
            {building.devices.length > 0 ? <DevicesTable gateways={building.devices}/> : <div>No devices available</div>}
        </div>
    );
};

const onClick = buildingId => () => history.push(`/buildings/${buildingId}`);

class Component extends React.Component {
    render() {
        const { classes, position, building } = this.props;

        return (
            <Marker
                onClick={onClick(building.id)}
                position={position}
                icon={{
                    url: mapStatusToIcon(building.status),
                    scaledSize: { width: 20, height: 25 }
                }}
                tooltip={getTooltip(classes, building)}
            />
        );
    }
}

Component.propTypes = {
    building: PropTypes.object.isRequired
};

export default withStyles(styles)(Component);
