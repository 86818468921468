import Checkbox from '@material-ui/core/Checkbox';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from 'Components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import icons from 'utils/ui/icons';
import styles, { MenuProps } from './styles';

const Component = props => {
    const { classes, disabled, schema, input, label, meta } = props;
    const { formData } = schema;

    function handleChange(event) {
        input.onChange(event.target.value);
    }

    const options = formData.map(item => ({
        key: item.id,
        label: item.name
    }));

    return (
        <FormControl
            className={classes.formControl}
            disabled={disabled}
            error={meta.touched && !!meta.error}
            style={{
                marginTop: 16,
                marginBottom: 8
            }}
            variant="filled"
        >
            <InputLabel
                htmlFor="select-multiple-checkbox"
                shrink={true}
                style={{
                    transform: 'translate(12px, -15px) scale(0.75)'
                }}
            >
                {label}
            </InputLabel>
            <Select
                classes={{ icon: classes.icon }}
                input={
                    <FilledInput
                        id="select-multiple-checkbox"
                        style={{ height: 30, fontSize: '0.65rem', paddingBottom: 16 }}
                    />
                }
                MenuProps={MenuProps}
                multiple
                onChange={handleChange}
                renderValue={
                    selected =>
                        options.filter(option => selected.includes(option.key))
                        .map(option => option.label)
                        .join(', ')
                }
                value={input.value ? input.value : []}
            >
                {options.map(option => (
                    <MenuItem
                        classes={{ root: classes.menuItemRoot }}
                        key={option.key}
                        value={option.key}
                    >
                        <Checkbox
                            checked={input.value ? input.value.includes(option.key) : false}
                            checkedIcon={<Icon icon={icons.checkSquare} size="sm"/>}
                            icon={<Icon icon={icons.square} size="sm"/>}
                        />
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={option.label}
                        />
                    </MenuItem>
                ))}
            </Select>
            {
                meta.touched && !!meta.error ?
                    <FormHelperText id="helper-text" error> {meta.error}</FormHelperText> :
                    null
            }
        </FormControl>

    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.object, //injected by Field from Redux forms to control value and callbacks
    label: PropTypes.string,
    meta: PropTypes.object //injected by Field from Redux forms to access interaction state
};
