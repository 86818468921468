export default () => ({
    formControl: {
        width: '100%'
    },
    menuItemRoot: {
        paddingTop: 4,
        paddingBottom: 4
    },
    listItemText: {
        fontSize: '0.65rem'
    },
    icon: {
        marginTop: 6
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    }
};
