import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from 'Components/Icon';
import React from 'react';
import icons from 'utils/ui/icons';
import styles from './styles';

const Loader = ({ classes }) => {
    return (
        <div className={classes.container}>
            <div className={classes.spinner}>
                <Icon icon={icons.circle_notch} size="3x" spin/>
            </div>
            <div className={classes.loader}>
                <LinearProgress/>
            </div>
        </div>
    );
};

export default withStyles(styles)(Loader);
