import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from 'Components/Icon';
import { toggleBuildings } from 'ducks/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { organizationBuildings } from 'selectors/buildings';
import icons from 'utils/ui/icons';
import styles from './styles';

const Component = props => {
    const { building, buildings, classes, panelOpen, toggleBuildings } = props;

    useEffect(() => {
        if (panelOpen) {
            toggleBuildings();
        }
    }, []);

    const ExpandIcon = (
        <IconButton className={classes.smallButtons}>
            <Icon icon={icons.expand} size="xs"/>
        </IconButton>
    );

    return (
        <div className={panelOpen ? classes.buildingsExpanded : classes.buildingsCollapsed}>
            <ExpansionPanel classes={{ rounded: classes.rounded }} expanded={panelOpen} onChange={toggleBuildings}>
                <ExpansionPanelSummary
                    expandIcon={ExpandIcon}
                    classes={{
                        content: classes.contentExpansionPanel,
                        expanded: classes.expanded,
                        expandIcon: classes.expandIcon,
                        root: classes.rootExpansionPanel
                    }}
                >
                    <Typography className={classes.heading}>{building.name}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                    <List dense component="nav" className={classes.nav}>
                        {
                            buildings.map(building =>
                                <div className={classes.listItem} key={building.id}>
                                    <Link className={classes.link} onClick={toggleBuildings}
                                          to={`/buildings/${building.id}`}>
                                        <ListItem button>
                                            <ListItemText className={classes.listText} primary={building.name}/>
                                            <ListItemText/>
                                        </ListItem>
                                    </Link>
                                </div>
                            )
                        }
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

Component.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    building: state.buildings.models[ownProps.buildingId],
    buildings: organizationBuildings(state, ownProps),
    panelOpen: state.ui.showBuildings
});

const mapDispatchToProps = {
    toggleBuildings
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
