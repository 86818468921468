import { withStyles } from '@material-ui/core';
import { InfoBox, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React from 'react';
import generateMarker from 'utils/generateMarker';
import styles from './styles';

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.togglePopover = this.togglePopover.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            openPopover: false,
            openTooltip: false
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.openPopover === true) {
            this.togglePopover();
        }
    }

    togglePopover() {
        this.setState({
            openPopover: !this.state.openPopover
        });
    }

    toggleTooltip() {
        this.setState({
            openTooltip: !this.state.openTooltip
        });
    }

    render() {
        const { classes, tooltip, popover, position, icon, classesOverride } = this.props;

        let infoBox;
        if (this.state.openTooltip || (!this.state.openTooltip && this.state.openPopover)) {
            infoBox = (
                <InfoBox
                    options={{
                        pane: 'floatPane',
                        pixelOffset: new window.google.maps.Size(
                            icon.scaledSize.width - icon.scaledSize.width / 2,
                            -icon.scaledSize.height
                        ),
                        alignBottom: true,
                        boxStyle: {
                            ...styles.infoBox
                        },
                        closeBoxURL: ''
                    }}
                    onCloseClick={this.togglePopover}
                    zIndex={10}
                >
                    <div
                        className={classesOverride ? classesOverride.infoBoxContent : classes.infoBoxContent}
                        ref={this.setWrapperRef}
                    >
                        <div> {!this.state.openPopover ? tooltip : popover} </div>
                    </div>
                </InfoBox>
            );
        }

        return (
            <Marker
                options={{ icon }}
                position={position}
                onClick={this.props.onClick || this.togglePopover}
                onMouseOver={this.toggleTooltip}
                onMouseOut={this.toggleTooltip}
                zIndex={1}
            >
                {infoBox}
            </Marker>
        );
    }
}

Component.defaultProps = {
    tooltip: 'Tooltip information',
    popover: 'Popover information',
    position: { lat: 40.418061, lng: -3.696032 },
    icon: {
        url: generateMarker('none'),
        scaledSize: { width: 20, height: 25 }
    }
};

Component.propTypes = {
    tooltip: PropTypes.element,
    //popover: PropTypes.element,
    position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
    }).isRequired,
    icon: PropTypes.shape({
        url: PropTypes.string.isRequired,
        scaledSize: PropTypes.shape({
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired
        })
    }),
    classesOverride: PropTypes.shape({
        styleInfoBoxContent: PropTypes.object.isRequired
    })
};

export default withStyles(styles)(Component);
