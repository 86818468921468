import EntityView from 'Components/EntityView';
import { actions } from 'ducks/equipmentCategories';
import React from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const properties = model => [
    { label: 'ID', content: model.id },
    { label: 'Name', content: model.name },
    { label: 'Input protocols', content: model.in_protocol_name },
    { label: 'Output protocols', content: model.out_protocol_name },
    { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props =>
    <EntityView
        disableDelete
        disableEdit
        tabsHeader={'Equipment Category details'}
        properties={properties}
        {...props}
    />;

const mapStateToProps = state => ({
    loading: state.equipmentCategories.loading,
    model: state.equipmentCategories.models[state.drawerView.entityId],
    root: state.users.models[state.auth.currentUserId].root
});

const mapDispatchToProps = ({
    remove: actions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
