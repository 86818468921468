import Panel from 'Components/Panel';
import { actions as logActions } from 'ducks/logs';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Table from './Table';

const Component = props => {
    const { device } = props;
    const { startPoolingRealtimeData, stopPoolingRealtimeData } = props;

    const [realtime, setRealtime] = React.useState(true);

    useEffect(() => {
        if (realtime) {
            startPoolingRealtimeData(device.id);
        }

        return () => stopPoolingRealtimeData(device.id);
    }, []);

    const handleRealtimeChange = newRealtimeValue => {
        newRealtimeValue ? startPoolingRealtimeData(device.id) : stopPoolingRealtimeData(device.id);

        setRealtime(newRealtimeValue);
    };

    return (
        <React.Fragment>
            <Panel realtime={realtime} setRealtime={handleRealtimeChange}>
                <Table device={device} realtime={realtime}/>
            </Panel>
        </React.Fragment>
    );
};

const mapDispatchToProps = ({
    startPoolingRealtimeData: logActions.startPoolingRealtimeData,
    stopPoolingRealtimeData: logActions.stopPoolingRealtimeData
});

export default connect(null, mapDispatchToProps)(Component);
