import Circle from 'Components/Circle';
import React from 'react';
import statusToColor from 'utils/statusToColor';

const statusToComponent = status => {
    const color = statusToColor(status);
    return (<React.Fragment>
        <Circle color={color}/>
        &nbsp;
        <span style={{ color: color }}>{status.toUpperCase()}</span>
    </React.Fragment>);
};

export default statusToComponent;
