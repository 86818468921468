import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import Loader from 'Components/Loader';
import React, { useEffect, useState } from 'react';

const fitBounds = (map, markers) => {
    if (markers.length === 0) {
        return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
};

const renderMarkers = markers => markers.map((marker, index) => {
    const { Component, lat, lng, props } = marker;

    return (
        <Component key={index} position={{ lat, lng }} {...props}/>
    );
});

const renderDecorators = decorators => decorators.map((decorator, index) => (
        <div key={index} style={decorator.style}>
            {decorator.Component}
        </div>
    )
);

const Component = props => {
    const { decorators, defaultCenter, defaultZoom, markers = [] } = props;
    const [map, setMap] = useState(null);

    useEffect(() => {
        if (map) {
            fitBounds(map, markers);
        }
    }, [map, markers]);

    const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: process.env.GOOGLE_MAPS_KEY });

    const renderMap = () => {
        return (
            <GoogleMap
                center={defaultCenter}
                mapContainerStyle={{ height: '100%', width: '100%' }}
                onLoad={map => setMap(map)}
                options={{
                    fullscreenControl: false,
                    mapTypeControl: false,
                    mapTypeId: 'roadmap',
                    maxZoom: 13,
                    minZoom: 3,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: true
                }}
                zoom={defaultZoom}
            >
                {renderDecorators(decorators)}
                {renderMarkers(markers)}
            </GoogleMap>
        );
    };

    if (loadError) {
        return (
            <div>
                Map cannot be loaded right now, sorry.
            </div>
        );
    }

    return isLoaded ? renderMap() : <Loader/>;
};

export default React.memo(Component);
