import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/buildings';
import Show from 'Pages/Metadata/Show';
import React from 'react';
import { connect } from 'react-redux';
import columns from 'utils/columns/buildings';
import history from '~/history';
import New from './New';

const onRowClick = event => history.push(`/buildings/${event.target.dataset.id}`);

const Components = props => {
    const { superuser } = props.currentUser;

    return (
        <EntitiesView
            columns={columns}
            newComponent={superuser ? New : null}
            onRowClick={onRowClick}
            showComponent={Show}
            {...props}
        />
    );
};

const mapStateToProps = state => {
    const { allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection } = state.buildings;

    return {
        allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection,
        currentUser: state.users.models[state.auth.currentUserId]
    };
};

const { changeSearch, changeSort, fetchAll, toggleAll, toggleOne } = actions;

const mapDispatchToProps = ({
    changeSearch,
    changeSort,
    fetchData: fetchAll,
    toggleAll,
    toggleOne: event => {
        event.stopPropagation();
        return toggleOne(event.target.name);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Components);
