import { sortBy } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

const Buildings = props => {
    const { rows } = props;

    return sortBy(rows, 'name').map((row, index) => {
            return (
                <React.Fragment key={row.id}>
                    <Link to={`/buildings/${row.id}`}>{row.name}</Link>
                    {index !== rows.length - 1 ? <>,&nbsp;</> : null}
                </React.Fragment>
            );
        }
    );
};

export default Buildings;
