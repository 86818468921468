import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import { sortBy } from 'lodash';
import NewDevice from 'Pages/Tree/Device/New';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { selectRootDevices } from 'selectors/devices';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';
import icons from 'utils/ui/icons';

const rootDeviceProperties = [
    { label: 'Name', dataKey: 'name' },
    { label: 'Last Update', dataKey: 'updated_at', transform: model => `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props => {
    const { buildingId, rootDevices } = props;
    const [openDrawer, setOpenDrawer] = useState(false);
    const closeDrawer = () => setOpenDrawer(false);

    const buttons = [{ icon: icons.addEntity, onClick: () => setOpenDrawer(true), tooltip: 'New child device' }];

    return (
        <React.Fragment>
            <Panel buttons={buttons}>
                <Table columns={rootDeviceProperties} rows={sortBy(rootDevices, 'name')}/>
            </Panel>
            <Drawer onClose={closeDrawer} open={openDrawer}>
                <NewDevice buildingId={buildingId} parentDeviceId={null} onSave={closeDrawer}/>
            </Drawer>
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => ({
    rootDevices: selectRootDevices(state, ownProps)
});

export default connect(mapStateToProps)(Component);
