import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Loader from 'Components/ScopedLoader';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';
import TablePaginationActions from './TablePaginationActions';

const Component = props => {
    const
        { classes, columns, loading, onRowClick, rows } = props,
        [page, setPage] = React.useState(0),
        onChangePage = page => setPage(page),
        [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage);

    if (loading) {
        return (
            <Loader/>
        );
    }

    const Columns = columns.map(({ label }) => (
        <TableCell className={classes.tableCellHeader} key={label}>{label}</TableCell>
    ));

    const Rows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
        const
            onClick = onRowClick ? () => onRowClick(row.id, row) : null,
            style = { cursor: onRowClick ? 'pointer' : 'auto' };

        return (
            <TableRow className={classes.tableRow} key={row.id} onClick={onClick} style={style}>
                {
                    columns.map(column => {
                        let value = column.transform ? column.transform(row) : row[column.dataKey];
                        // only skip null and undefined but draw '' and 0
                        if (value === undefined || value === null) {
                            return (
                                <TableCell className={classes.tableCell} key={column.dataKey}/>
                            );
                        } else {
                            return (
                                <TableCell className={classes.tableCell} key={column.dataKey}>
                                    {value}
                                </TableCell>
                            );
                        }
                    })
                }
            </TableRow>
        );
    });

    let pagination;
    if (rows.length > rowsPerPage) {
        const onChangeRowsPerPage = event => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        pagination = (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        ActionsComponent={TablePaginationActions}
                        colSpan={3}
                        count={rows.length}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                    />
                </TableRow>
            </TableFooter>
        );
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.tableRowHeader}>{Columns}</TableRow>
                    </TableHead>
                    <TableBody>
                        {Rows}
                    </TableBody>
                    {pagination}
                </Table>
            </div>
        </Paper>
    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    onRowClick: PropTypes.func,
    rows: PropTypes.array.isRequired,
    rowsPerPage: PropTypes.number
};

Component.defaultProps = {
    rowsPerPage: 100
};
