import { createSelector } from 'reselect';

const readings = state => state.readings.models;
const equipmentId = (_, equipmentId) => equipmentId;

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

export const readingsByEquipmentId = createSelector(
    readings,
    equipmentId,
    (readings, equipmentId) => Object
    .values(readings)
    .filter(reading => reading.equipment_id === equipmentId)
    .sort((a, b) => collator.compare(a.name, b.name))
);

export default {
    readingsByEquipmentId
};
