import { createSelector } from 'reselect';

const devices = state => state.devices.models;
const buildingId = (_, ownProps) => ownProps.buildingId;

export const selectRootDevices = createSelector(
    devices,
    buildingId,
    (devices, buildingId) => Object.values(devices).filter(device => {
        return device.building_id === buildingId && device.parent_id === null;
    })
);
