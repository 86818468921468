import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as measurementActions } from 'ducks/measurements';
import React from 'react';
import { connect } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';
import sortMeasurements from 'utils/sortMeasurements';

let formInitialValues = { metadata_values: [], name: '', rate: 900 };

const Component = props => {
    const { device, devices, measurements, metadatas, reading, submitting, validRates } = props;
    const { create, closeDrawer } = props;

    const formSchema = [];

    if (['WiseInput', 'WiseCurrent'].includes(device.equipment_name)) {
        formSchema.push(
            {
                label: 'Name\x2a',
                name: 'name',
                type: 'text',
                validate: [validators.required]
            }
        );
    }

    formSchema.push(
        {
            formData: validRates.map(rate => ({ id: rate, name: rate })),
            label: 'Rate\x2a',
            name: 'rate',
            type: 'select',
            validate: [validators.required]
        }
    );

    if (device.equipment_category_name === 'Digital Meter') {
        const usedPortIds = [];
        Object.values(measurements).forEach(measurement => {
            if (measurement.port_id && devices[measurement.device_id].parent_id === device.parent_id) {
                usedPortIds.push(measurement.port_id);
            }
        });

        const availablePorts = Object.values(measurements).filter(measurement => measurement.device_id === device.parent_id && !usedPortIds.includes(measurement.id)).sort(sortMeasurements);

        formSchema.push({
            formData: availablePorts,
            label: 'Connected Port\x2a',
            name: 'port_id',
            type: 'select',
            validate: [validators.required]
        });
    }

    let metadataSchemas = metadataFields(metadatas);
    if (device.equipment_category_name === 'WiseCurrent' && reading.reading_kind_name.startsWith('EA+')) {
        metadataSchemas = [];
    }
    formSchema.push(...metadataSchemas);

    const onSubmit = formValues => {
        let data = {
            ...serializeForm(formValues, formSchema),
            device_id: device.id,
            reading_id: reading.id
        };

        create(data, closeDrawer);
    };

    formInitialValues.name = reading.name;
    formInitialValues.rate = 900;

    return (
        <FormViewer label="Add Measurement">
            <Form
                formInitialValues={formInitialValues}
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = (state, ownProps) => ({
    devices: state.devices.models,
    measurements: state.measurements.models,
    metadatas: selectorMetadatas.metadataFromMeasurement(state, ownProps),
    reading: state.readings.models[ownProps.readingId],
    submitting: state.measurements.submitting,
    validRates: state.bootstrap.valid_rates
});

const mapDispatchToProps = ({
    create: measurementActions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
