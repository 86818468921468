import { FULFILLED } from 'ducks/actionTypes';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import pluralize from 'utils/pluralize';
import { create, fetch, fetchWithParams, remove, searchDebounce, update } from 'utils/sagasHelpers';

export default (ENTITY, url, options = {}) => {
    const PLURAL = pluralize(ENTITY);

    function* watchCreate() {
        yield takeLatest(`CREATE_${ENTITY}`, create(`CREATE_${ENTITY}`, url));
    }

    function* watchDelete() {
        yield takeLatest(`DELETE_${ENTITY}`, remove(`DELETE_${ENTITY}`, url));
    }

    function* watchFetchAll() {
        yield takeLatest(`FETCH_${PLURAL}`, fetchWithParams(`FETCH_${PLURAL}`, url));
    }

    function* watchFetchOne() {
        yield takeEvery(`FETCH_${ENTITY}`, fetch(`FETCH_${ENTITY}`, url));
    }

    function* watchSearch() {
        yield takeLatest(`CHANGE_${PLURAL}_SEARCH`, searchDebounce(`CHANGE_${PLURAL}_SEARCH${FULFILLED}`, options));
    }

    function* watchUpdate() {
        yield takeLatest(`UPDATE_${ENTITY}`, update(`UPDATE_${ENTITY}`, url));
    }

    return [
        watchCreate,
        watchDelete,
        watchFetchAll,
        watchFetchOne,
        watchSearch,
        watchUpdate
    ];
};
