import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/organizations';
import { sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import validators from 'utils/forms/validators';

const Component = props => {
    const countries = props.countries.map(country => ({ id: country.code, name: country.name }));

    const formSchema = [
        {
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        },
        {
            formData: sortBy(countries, 'name'),
            label: 'Country\x2a',
            name: 'country_code',
            type: 'select',
            validate: [validators.required]
        }
    ];

    return (
        <EntityManageView
            formSchema={formSchema}
            label="Create Organization"
            {...props}
        />
    );
};

const mapStateToProps = state => ({
    countries: state.bootstrap.countries,
    submitting: state.organizations.submitting
});

const mapDispatchToProps = ({
    create: actions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
