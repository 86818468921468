import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants';

export default theme => ({
    mainContainer: {
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        backgroundColor: theme.palette.backgrounds.main,
        padding: '0px 10px 0px'
    },
    headerContainer: {
        height: `${TABS_HEIGHT + SPACER_MINI}px`
    },
    bottomContainer: {
        height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI + SPACER_MINI}px)`,
        paddingBottom: 5, // Allows seeing the shadow of last panel
        overflowY: 'auto'
    }
});
