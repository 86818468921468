import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Loader from 'Components/ScopedLoader';
import PropTypes from 'prop-types';
import React from 'react';
import { EXPANDED_EXPANSION_PANEL_HEADER_NEW } from 'utils/ui/constants';
import styles from './styles';

const Component = props => {
    const { classes, header, id, loading } = props;

    let Header;
    if (header) {
        Header = (
            <ExpansionPanelSummary
                style={{
                    height: EXPANDED_EXPANSION_PANEL_HEADER_NEW,
                    minHeight: EXPANDED_EXPANSION_PANEL_HEADER_NEW
                }}
            >
                <Typography gutterBottom className={classes.heading}>
                    {header}
                </Typography>
            </ExpansionPanelSummary>
        );
    }
    return (
        <ExpansionPanel
            id={id}
            classes={{
                expanded: classes.expandedExpansionPanel
            }}
            expanded={true}
        >
            {Header}
            <ExpansionPanelDetails
                classes={header ?
                    { root: classes.expansionPanelDetailsWithHeader } :
                    { root: classes.expansionPanelDetailsWithoutHeader }
                }
            >
                <Grid item xs={12}>
                    {
                        loading ?
                            <Loader/> :
                            props.children
                    }
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    header: PropTypes.string,
    id: PropTypes.string,
    loading: PropTypes.bool
};
