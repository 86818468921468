import WiseLed from 'Components/WiseLed';
import React from 'react';
import { unixToDate } from 'utils/date';

export default [
    {
        width: 200,
        flexGrow: 1.0,
        label: 'ID',
        dataKey: 'id'
    },
    {
        width: 200,
        flexGrow: 1.0,
        label: 'Balena ID',
        dataKey: 'balena_id'
    },
    {
        width: 200,
        flexGrow: 1.0,
        label: 'Balena UUID',
        dataKey: 'balena_uuid'
    },
    {
        width: 120,
        flexGrow: 1.0,
        label: 'Last Seen',
        dataKey: 'updated_at',
        cellContentRenderer: unixToDate,
        searchable: false
    },
    {
        width: 120,
        flexGrow: 1.0,
        label: 'WebPassword',
        dataKey: 'web_password',
        searchable: false
    },
    {
        width: 120,
        flexGrow: 1.0,
        label: 'Status',
        dataKey: 'status'
    },
    {
        width: 120,
        flexGrow: 1.0,
        label: 'Code',
        dataKey: 'activation_code',
        cellContentRenderer: code => <WiseLed code={code}/>
    }
];
