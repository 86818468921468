import { unixToDate } from 'utils/date';

export default [
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Name',
        dataKey: 'name'
    },
    {
        width: 75,
        flexGrow: 1.0,
        label: 'Usable?',
        dataKey: 'usable',
        cellContentRenderer: usable => usable ? 'Yes' : 'No'
    },
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Category',
        dataKey: 'equipment_category_name'
    },
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Type',
        dataKey: 'equipment_type_name'
    },
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Input protocol',
        dataKey: 'in_protocol_name',
        disableSort: false
    },
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Output protocol',
        dataKey: 'out_protocol_name',
        disableSort: false
    },
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Readings #',
        dataKey: 'readings_count',
        searchable: false
    },
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Devices #',
        dataKey: 'devices_count',
        searchable: false
    },
    {
        width: 100,
        flexGrow: 1.0,
        label: 'Last Modification',
        dataKey: 'updated_at',
        cellContentRenderer: unixToDate,
        searchable: false
    }
];
