import columns from 'utils/columns/users';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';

import actionsFactory from 'utils/factories/actions';
import { USER } from './actionTypes';

// actions
const actions = actionsFactory(USER);
export { actions };

// reducer
const initialState = initialStateFactory(columns);
const reducer = reducerFactory(USER);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(USER, '/users');
export { sagas };
