import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/readings';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import formSelectors from 'selectors/form';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { equipmentId, equipments, formValues, metadatas, readingKinds } = props;
    const { change } = props;

    useEffect(() => {
        const readingKind = readingKinds.find(readingKind => readingKind.id === formValues.reading_kind_id);

        if (readingKind) {
            change('form', 'name', readingKind.name);
        }
    }, [formValues.reading_kind_id]);

    const formSchema = [];

    if (equipments[equipmentId].out_protocol_id === 6) {
        formSchema.push(
            {
                type: 'select',
                label: 'Reading Kind\x2a',
                name: 'reading_kind_id',
                validate: [validators.required],
                formData: sortBy(readingKinds, 'name')
            },
            {
                type: 'text',
                label: 'Name\x2a (avoid changing unless absolutely necessary)',
                name: 'name',
                validate: [validators.required]
            }
        );
    } else {
        formSchema.push(
            {
                type: 'text',
                label: 'Name\x2a (please use standardized names, eg: DI/DO/Port)',
                name: 'name',
                validate: [validators.required]
            }
        );
    }

    formSchema.push(...metadataFields(metadatas));

    return (
        <EntityManageView
            extraData={{ equipment_id: equipmentId }}
            formSchema={formSchema}
            label="New Reading"
            {...props}
        />
    );
};

const mapStateToProps = state => ({
    equipments: state.equipments.models,
    formValues: formSelectors.getFormValues(state),
    metadatas: selectorMetadatas.metadataFromReading(state),
    protocols: state.protocols.models,
    readingKinds: state.bootstrap.reading_kinds,
    equipmentId: state.drawerView.entityId,
    submitting: state.readings.submitting
});

const mapDispatchToProps = ({
    change: change,
    create: actions.create,
    update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
