export default (collection, params) => collection.reduce((array, model) => {
    let match = true;

    Object.keys(params || {}).forEach(key => {
        if (match) {
            match = model[key] === params[key];
        }
    });

    if (match) {
        array.push(model);
    }

    return array;
}, []);
