import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import Icon from 'Components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import icons from 'utils/ui/icons';
import styles from './styles';

const variantIcon = {
    success: icons.check,
    warning: icons.warning,
    error: icons.error,
    info: icons.info
};

// TODO: component should be refactored so the styles start including theme.palette.augmentColor.

const Content = props => {
    const { classes, className, message, variant, ...other } = props;

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <div id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} icon={variantIcon[variant]}/>
                    {message}
                </div>
            }
            {...other}
        />
    );
};

Content.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
};

const StyledContent = withStyles(styles.content)(Content);

const Alert = props => {
    const { message, open, placement, timeout, variant } = props;
    const { handleClose } = props;

    return (
        <Snackbar
            anchorOrigin={
                placement ?
                    placement :
                    { vertical: 'top', horizontal: 'right' }
            }
            open={open}
            autoHideDuration={timeout}
            onClick={handleClose}
            onClose={handleClose}
        >
            <StyledContent
                variant={variant ? variant : 'error'}
                message={message}
            />
        </Snackbar>
    );
};

Alert.propTypes = {
    handleClose: PropTypes.func,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    placement: PropTypes.shape({
        vertical: PropTypes.oneOf(['top', 'bottom']),
        horizontal: PropTypes.oneOf(['left', 'center', 'right'])
    }),
    timeout: PropTypes.number,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
};

export default withStyles(styles.snackbar)(Alert);
