import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from 'Components/Buttons/IconButton';
import Tabs from 'Components/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Component = (props) => {
    const { classes, currentTab, buttons = [], tabs } = props;

    const Buttons = buttons.map(button => <IconButton key={button.icon} {...button} />);

    return (
        <Grid container item xs={12} className={classes.boxShadowContainer}>
            <Grid item className={classes.tabs} elevation={0}>
                <Tabs currentTab={currentTab} elevation={0} tabs={tabs}/>
            </Grid>
            <Grid item xs>
                <Paper square elevation={0} className={classes.paper}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={12}>
                            {Buttons}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(Component);

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            id: PropTypes.string
        })
    ).isRequired,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string,
            icon: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.string)
            ]),
            href: PropTypes.string,
            onClick: PropTypes.func,
            tooltip: PropTypes.string,
            spin: PropTypes.bool,
            requireConfirmation: PropTypes.bool
        })
    )
};
