import columns from 'utils/columns/protocols';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { PROTOCOL } from './actionTypes';

// actions
const actions = actionsFactory(PROTOCOL);
export { actions };

// reducer
const initialState = initialStateFactory(columns);
const reducer = reducerFactory(PROTOCOL);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(PROTOCOL, '/protocols');
export { sagas };
