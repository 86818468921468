import { all, put, take, takeLatest } from 'redux-saga/effects';
import { fetch } from 'utils/sagasHelpers';
import { BOOT, BOOTSTRAP, FETCH_BUILDINGS, FETCH_EQUIPMENT_CATEGORIES, FETCH_EQUIPMENTS, FETCH_METADATA_RULES, FETCH_METADATAS, FETCH_ORGANIZATIONS, FETCH_PROTOCOLS, FETCH_READINGS, FETCH_USERS, FULFILLED } from './actionTypes';

// reducer
export default (state = {}, { type, payload }) => {
    if (type === BOOTSTRAP + FULFILLED) {
        return payload;
    }

    return state;
};

// sagas
function* watchBoot() {
    yield takeLatest(BOOT, boot);
}

function* boot() {

    try {
        yield put({ type: BOOTSTRAP });
        yield put({ type: FETCH_BUILDINGS });
        yield put({ type: FETCH_EQUIPMENT_CATEGORIES });
        yield put({ type: FETCH_EQUIPMENTS });
        yield put({ type: FETCH_METADATAS });
        yield put({ type: FETCH_METADATA_RULES });
        yield put({ type: FETCH_ORGANIZATIONS });
        yield put({ type: FETCH_PROTOCOLS });
        yield put({ type: FETCH_READINGS });
        // TODO remove:template yield put({ type: FETCH_TEMPLATES });
        yield put({ type: FETCH_USERS });
        yield all([
            take(BOOTSTRAP + FULFILLED),
            take(FETCH_BUILDINGS + FULFILLED),
            take(FETCH_EQUIPMENT_CATEGORIES + FULFILLED),
            take(FETCH_EQUIPMENTS + FULFILLED),
            take(FETCH_METADATAS + FULFILLED),
            take(FETCH_METADATA_RULES + FULFILLED),
            take(FETCH_ORGANIZATIONS + FULFILLED),
            take(FETCH_PROTOCOLS + FULFILLED),
            take(FETCH_READINGS + FULFILLED),
            // TODO remove:template take(FETCH_TEMPLATES + FULFILLED),
            take(FETCH_USERS + FULFILLED)
        ]);
        yield put({ type: BOOT + FULFILLED });
    } catch (error) {
        console.error(error);
    }
}

function* watchBootstrap() {
    yield takeLatest(BOOTSTRAP, fetch(BOOTSTRAP, '/bootstrap'));
}

const sagas = [
    watchBoot,
    watchBootstrap
];

export { sagas };
