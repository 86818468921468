import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/users';
import React from 'react';
import { connect } from 'react-redux';
import columns from 'utils/columns/users';
import Edit from './Edit';
import New from './New';
import Show from './Show';

const Component = props => {
    return (
        <EntitiesView
            columns={columns}
            editComponent={Edit}
            newComponent={New}
            showComponent={Show}
            {...props}
        />
    );
};

const mapStateToProps = (
    {
        users: { allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection },
        drawerView: { entity: entityType }
    }) =>
    ({
        allChecked, checked, ids, models, loading, searching, searchValue, sortBy, sortDirection,
        entityType
    });

const { changeSearch, changeSort, fetchAll, toggleAll, toggleOne } = actions;

const mapDispatchToProps = ({
    changeSearch,
    changeSort,
    fetchData: fetchAll,
    toggleAll,
    toggleOne: event => {
        event.stopPropagation();
        return toggleOne(event.target.name);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
