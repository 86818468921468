export default theme => ({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: theme.zIndex.tooltip + 1
    },
    spinner: {
        color: theme.palette.primary.main,
        left: '50%',
        position: 'fixed',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    loader: {
        color: theme.palette.primary.light,
        left: '35%',
        position: 'fixed',
        top: '65%',
        width: '30%'
    }
});
