import { capitalize } from 'lodash';

export default [
    {
        width: 200,
        flexGrow: 1.0,
        label: 'Name',
        dataKey: 'name'
    },
    {
        width: 80,
        flexGrow: 1.0,
        label: 'Type',
        dataKey: 'metadata_type',
        disableSort: true,
        cellContentRenderer: type => capitalize(type)
    },
    {
        width: 60,
        flexGrow: 1.0,
        label: 'Metadata Rules #',
        dataKey: 'metadata_rules_count'
    },
    {
        width: 60,
        flexGrow: 1.0,
        label: 'Metadata Values #',
        dataKey: 'metadata_values_count'
    },
    {
        width: 250,
        flexGrow: 1.0,
        label: 'Admissible Values',
        dataKey: 'enumerator_members',
        cellContentRenderer: item => item ? item : '---'
    }
];
