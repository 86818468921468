import { isFunction } from 'lodash';
import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import columns from 'utils/columns/wise_boxes';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { apiRequest } from 'utils/request';
import { handleError } from 'utils/sagasHelpers';
import history from '~/history';
import { CLAIM_WISE_BOX, FETCH_WISE_BOXES, FULFILLED, LOAD_BUILDING, REJECTED, START_POOLING_WISE_BOXES, STOP_POOLING_WISE_BOXES, WISE_BOX } from './actionTypes';

// actions
const actions = actionsFactory(WISE_BOX);

actions.claim = (id, data, callback) => ({
    meta: callback,
    payload: { id, data },
    type: CLAIM_WISE_BOX
});

actions.startPooling = timeout => ({
    payload: timeout,
    type: START_POOLING_WISE_BOXES
});

actions.stopPooling = () => ({
    type: STOP_POOLING_WISE_BOXES
});

export { actions };

// reducer
const initialState = initialStateFactory(columns);
const reducer = reducerFactory(WISE_BOX);

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CLAIM_WISE_BOX:
            return reducer({ ...state, errorMessage: '', submitting: true }, action);

        case CLAIM_WISE_BOX + FULFILLED:
            return reducer({ ...state, errorMessage: '', submitting: false }, action);

        case CLAIM_WISE_BOX + REJECTED:
            return reducer({ ...state, errorMessage: payload, submitting: false }, action);

        default:
            return reducer(state, action);
    }
};

// sagas
const sagas = sagasFactory(WISE_BOX, '/wise_boxes');

const DEFAULT_POOL_INTERVAL = 15;

function* watchClaim() {
    yield takeLatest(CLAIM_WISE_BOX, function* ({ meta: callback, payload }) {
        try {
            const { data: device } = yield apiRequest(`/wise_boxes/${payload.id}/claim`, 'POST', payload.data);
            yield put({ type: CLAIM_WISE_BOX + FULFILLED });
            yield put({ type: LOAD_BUILDING, payload: device.building_id });
            if (isFunction(callback)) {
                callback();
            }
            history.push(`/devices/${device.id}`);
        } catch (error) {
            const errorObject = handleError(CLAIM_WISE_BOX, error);
            yield put(errorObject);
        }
    });
}

function* poolWiseBoxes(interval) {
    try {
        while (true) {
            yield delay(interval * 1000);
            yield put({ type: FETCH_WISE_BOXES });
        }
    } catch (error) {
        console.error(error);
    }
}

function* watchPooling() {
    try {
        while (true) {
            let action = yield take(START_POOLING_WISE_BOXES);
            yield race({
                task: call(poolWiseBoxes, action.payload || DEFAULT_POOL_INTERVAL),
                cancel: take(STOP_POOLING_WISE_BOXES)
            });
        }
    } catch (error) {
        console.error(error);
    }
}

sagas.push(
    watchClaim,
    watchPooling
);
export { sagas };
