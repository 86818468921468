import pluralize from 'utils/pluralize';

const noOp = () => {};

export default TYPE => {
    const PLURAL = pluralize(TYPE);

    return {
        changeSearch: searchValue => ({
            type: `CHANGE_${PLURAL}_SEARCH`,
            payload: searchValue
        }),

        changeSort: ({ sortBy, sortDirection }) => ({
            type: `CHANGE_${PLURAL}_SORT`,
            payload: {
                sortBy,
                sortDirection
            }
        }),

        create: (data, redirect) => ({
            type: `CREATE_${TYPE}`,
            payload: data,
            meta: redirect
        }),

        remove: (id, redirect) => ({
            type: `DELETE_${TYPE}`,
            payload: id,
            meta: redirect || noOp
        }),

        fetchAll: (params, meta) => ({
            type: `FETCH_${PLURAL}`,
            payload: params,
            meta: meta
        }),

        fetchOne: id => ({
            type: `FETCH_${TYPE}`,
            payload: id
        }),

        update: (id, data, redirect) => ({
            type: `UPDATE_${TYPE}`,
            payload: { id, data },
            meta: redirect
        }),

        toggleAll: () => ({
            type: `TOGGLE_ALL_${PLURAL}`
        }),

        toggleOne: id => ({
            type: `TOGGLE_ONE_${TYPE}`,
            payload: id
        })
    };
};
