import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Loading from 'Components/ScopedLoader';
import { actions as deviceActions } from 'ducks/devices';
import { loadBuilding, refreshBuilding } from 'ducks/loadBuilding';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Building from './Building';
import BuildingList from './BuildingList';
import Device from './Device';
import styles from './styles';
import Tree from './Tree';

const Component = props => {
    const { buildingId, classes, deviceId, loadBuilding, loaded, loadDevice, mode, panelOpen, section, validBuilding, validDevice } = props;

    useEffect(() => {
        if (mode === 'building' && !loaded) {
            loadBuilding(buildingId);
        } else if (mode === 'device' && !validDevice) {
            loadDevice(deviceId);
        } else if (mode === 'device' && !loaded) {
            loadBuilding(buildingId);
        }
    }, [mode, buildingId, deviceId]);

    if (!loaded) {
        return (
            <Loading/>
        );
    }

    if (!validBuilding) {
        return (
            <div>Invalid building</div>
        );
    }

    if (deviceId && !validDevice) {
        return (
            <div>Invalid device</div>
        );
    }

    let tree = (
        <Tree buildingId={buildingId} deviceId={deviceId}/>
    );

    if (panelOpen) {
        tree = null;
    }

    let Container;
    if (deviceId) {
        Container = <Device deviceId={deviceId} section={section}/>;
    } else {
        Container = <Building buildingId={buildingId} section={section}/>;
    }

    return (
        <React.Fragment>
            <Grid item xs={3} className={classes.leftContainer}>
                <BuildingList buildingId={buildingId}/>
                {tree}
            </Grid>
            <Grid item xs={9} className={classes.rightContainer}>
                {Container}
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {
    const mode = ownProps.match.params.deviceId ? 'device' : 'building';

    if (mode === 'building') {
        return {
            mode,
            buildingId: ownProps.match.params.buildingId,
            section: ownProps.match.params.section,
            loaded: state.loadedBuildings[ownProps.match.params.buildingId],
            panelOpen: state.ui.showBuildings,
            validBuilding: !!state.buildings.models[ownProps.match.params.buildingId]
        };
    } else {
        const device = state.devices.models[ownProps.match.params.deviceId];
        const buildingId = device && device.building_id;

        return {
            mode,
            buildingId: buildingId,
            deviceId: ownProps.match.params.deviceId,
            section: ownProps.match.params.section,
            loaded: state.loadedBuildings[buildingId],
            panelOpen: state.ui.showBuildings,
            validBuilding: !!state.buildings.models[buildingId],
            validDevice: !!device
        };
    }
};

const mapDispatchToProps = ({
    loadBuilding,
    loadDevice: deviceActions.fetchOne,
    refreshBuilding
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
