import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/users';
import { sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import validators from 'utils/forms/validators';

const Component = props => {
    let organizations = sortBy(Object.values(props.organizations), 'name');

    const formSchema = [
        {
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        },
        {
            label: 'Email\x2a',
            name: 'email',
            type: 'text',
            validate: [validators.required]
        },
        {
            formData: organizations,
            label: 'Organizations\x2a',
            name: 'organization_ids',
            type: 'selectMultiple',
            validate: [validators.required]
        },
        {
            type: 'checkBox',
            label: 'Administrator?',
            name: 'administrator'
        },
        {
            type: 'checkBox',
            label: 'Superuser?',
            name: 'superuser'
        },
        {
            type: 'checkBox',
            label: 'Enabled?',
            name: 'enabled'
        }
    ];

    return (
        <EntityManageView
            formSchema={formSchema}
            label="Create User"
            {...props}
        />
    );
};

const mapStateToProps = state => ({
    organizations: state.organizations.models,
    submitting: state.users.submitting
});

const mapDispatchToProps = ({
    create: actions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
