import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

const equipmentCategories = state => state.equipmentCategories.models;

const equipmentCategoriesArray = createSelector(
    equipmentCategories,
    equipmentCategories => sortBy(Object.values(equipmentCategories), 'name')
);

export default {
    equipmentCategoriesArray
};
