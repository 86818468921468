import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandedPanel from 'Components/ExpandedPanel';
import { actions as drawerViewActions } from 'ducks/drawerView';
import { actions as equipmentActions } from 'ducks/equipment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import readingSelectors from 'selectors/readings';
import Details from './Details';
import Devices from './Devices';
import Readings from './Readings';
import styles from './styles';
import Toolbar from './Toolbar';

const EquipmentPage = props => {
    const { classes, disableButtons = false, id, model, readings } = props;
    const { fetchOne, viewEntity } = props;

    useEffect(() => {
        fetchOne(id);
    }, [id]);

    let ReadingsList;
    if (model.can_have_readings) {
        const onRowClick = id => viewEntity(id, 'READING');

        ReadingsList = (
            <ExpandedPanel id="readings" header="Readings">
                <Readings onRowClick={onRowClick} readings={readings}/>
            </ExpandedPanel>
        );
    }

    return (
        <Grid item xs={12} className={classes.mainContainer}>
            <Grid container className={classes.headerContainer}>
                <Toolbar disableButtons={disableButtons} equipment={model}/>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>
                <ExpandedPanel id="properties" header="Details">
                    <Details model={model}/>
                </ExpandedPanel>
                {ReadingsList}
                <ExpandedPanel id="devices" header="Devices">
                    <Devices devices={model.devices}/>
                </ExpandedPanel>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.id || state.drawerView.entityId;
    return {
        id,
        model: state.equipments.models[id],
        readings: readingSelectors.readingsByEquipmentId(state, id)
    };
};

const mapDispatchToProps = ({
    fetchOne: equipmentActions.fetchOne,
    viewEntity: drawerViewActions.viewEntity
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentPage));
