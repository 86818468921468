import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import { openSidebar } from 'ducks/ui';
import logoImage from 'images/core/logo.png';
import textImage from 'images/core/text.png';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Buttons from './Buttons';
import Sidebar from './Sidebar';
import styles from './styles';

const Component = props => {
    const { classes, sections } = props;
    const { openSidebar } = props;

    return (
        <React.Fragment>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar className={classes.toolbar} variant="dense">
                    <IconButton className={classes.menuButton} onClick={openSidebar} onMouseEnter={openSidebar}>
                        <img alt="-" className={classes.logo} src={logoImage}/>
                    </IconButton>
                    <img alt="WiseMetering" className={classes.text} src={textImage}/>
                    <div className={classes.grow}/>
                    <Buttons/>
                </Toolbar>
            </AppBar>
            <Sidebar sections={sections}/>
        </React.Fragment>
    );
};

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    sections: PropTypes.array.isRequired,
    openSidebar: PropTypes.func.isRequired
};

const mapDispatchToProps = ({ openSidebar });

export default connect(null, mapDispatchToProps)(withStyles(styles)(Component));
