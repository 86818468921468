export default {
    infoBox: {
        boxShadow: '3px 3px 10px rgba(0,0,0, 0.6)',
        borderRadius: '4px'
    },
    infoBoxContent: {
        background: 'lightGrey',
        padding: '1em',
        //textAlign: 'center',
        border: '1px solid grey',
        borderRadius: '4px',
        opacity: 0.9
    }
};
