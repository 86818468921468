import Alert from 'Components/Alert';
import { cleanUserFeedback } from 'ducks/userFeedback';
import React from 'react';
import { connect } from 'react-redux';

const defaultPlacement = { vertical: 'bottom', horizontal: 'left' };
const defaultTimeout = 5000;

const Message = props => {
    const { userFeedback } = props;
    const { cleanUserFeedback } = props;

    return (
        <Alert
            open={userFeedback.message.length !== 0}
            message={userFeedback.message}
            handleClose={cleanUserFeedback}
            placement={userFeedback.placement || defaultPlacement}
            timeout={userFeedback.timeout || defaultTimeout}
            variant={userFeedback.type}
        />
    );
};

const mapStateToProps = state => ({
    userFeedback: state.userFeedback
});

const mapDispatchToProps = ({ cleanUserFeedback });

export default connect(mapStateToProps, mapDispatchToProps)(Message);
