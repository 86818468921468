import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';
import Icon from 'Components/Icon';
import { actions } from 'ducks/buildings';
import React from 'react';
import { connect } from 'react-redux';
import icons from 'utils/ui/icons';
import history from '~/history';
import styles from './styles';

const Component = props => {
    const { changeSearch, classes, searchValue } = props;

    return (
        <div className={classes.rootMain}>
            <Paper className={classes.root}>
                <InputBase
                    classes={{ root: classes.inputDiv, input: classes.input }}
                    placeholder="Search by building name, organization name or current status"
                    onChange={event => changeSearch(event.target.value)}
                    value={searchValue}
                />
                <IconButton className={classes.button} onClick={() => history.push('/buildings')}>
                    <Icon icon={icons.buildings} size="xs" fixedWidth/>
                </IconButton>
            </Paper>
        </div>
    );
};

const mapStateToProps = state => ({
    searchValue: state.buildings.searchValue
});

const mapDispatchToProps = ({
    changeSearch: actions.changeSearch
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
