import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { READING } from './actionTypes';

// actions
const actions = actionsFactory(READING);
export { actions };

// reducer
const initialState = initialStateFactory();
const reducer = reducerFactory(READING);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(READING, '/readings');
export { sagas };
