import { fade } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
    appBar: {
        padding: 0,
        zIndex: theme.zIndex.drawer + 1
    },
    root: {
        width: '100%'
    },
    avatar: {
        height: 44,
        width: 44
    },
    avatarText: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.white,
        cursor: 'pointer',
        height: 44,
        width: 44,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.light, 0.65),
            color: fade(theme.palette.primary.white, 0.65)
        }
    },
    avatarTextActive: {
        backgroundColor: fade(theme.palette.primary.light, 0.65),
        color: fade(theme.palette.primary.white, 0.65),
        height: 44,
        width: 44
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        color: theme.palette.primary.white,
        marginRight: 0,
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.05),
            borderRadius: 0
        }
    },
    title: {
        color: theme.palette.primary.white,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    logo: {
        maxHeight: 40,
        padding: 4
    },
    text: {
        maxHeight: 40,
        padding: 4
    },
    sectionDesktop: {
        display: 'flex'
    },
    toolbar: {
        paddingLeft: 0
    }
});
