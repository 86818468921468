import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { actions as wiseBoxActions } from 'ducks/wise_boxes';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { id, submitting, wise_boxes } = props;
    const { closeDrawer, fetchWiseBoxes, reclaim } = props;

    useEffect(() => {
        fetchWiseBoxes();
    }, []);

    const validWiseBoxes = sortBy(Object.values(wise_boxes), 'created_at').reverse().reduce((array, wise_box) => {
        if (wise_box.status === 'validated') {
            let name = `${wise_box.id} | last_seen: ${unixToDate(wise_box.created_at)}`;
            if (wise_box.activation_code) {
                let code = wise_box.activation_code.toString(2).padStart(3, 0).padStart(3, 0).split('').join('-');
                name += `  |  activation code: ${code}`;
            }
            array.push({ id: wise_box.id, name: name });
        }

        return array;
    }, []);

    let formSchema = [
        {
            type: 'select',
            label: 'WiseBox\x2a',
            name: 'wise_box_id',
            validate: [validators.required],
            formData: validWiseBoxes
        }
    ];

    const onSubmit = values => {
        const data = serializeForm(values, formSchema);

        reclaim(id, data.wise_box_id, () => {
            closeDrawer();
        });
    };

    return (
        <FormViewer label="Create Template">
            <Form
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = state => ({
    submitting: state.devices.submitting,
    wise_boxes: state.wise_boxes.models
});

const mapDispatchToProps = ({
    fetchWiseBoxes: wiseBoxActions.fetchAll,
    reclaim: deviceActions.reclaim
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
