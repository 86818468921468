import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import SimpleList from 'Components/SimpleList';
import { actions as buildingActions } from 'ducks/buildings';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';
import icons from 'utils/ui/icons';
import EditBuilding from './Edit';

const buildingProperties = model => [
    { label: 'ID', content: model.id },
    { label: 'Organization', content: model.organization_name },
    { label: 'Name', content: model.name },
    { label: 'Address', content: model.full_address },
    { label: 'Coordinates', content: `lat: ${model.latitude} - lng: ${model.longitude}` },
    { label: 'Creation', content: `${unixToDate(model.created_at)} - ${getUserName(model.created_by)}` },
    { label: 'Update', content: `${unixToDate(model.updated_at)} - ${getUserName(model.updated_by)}` }
];

const Component = props => {
    const
        { building, submitting } = props,
        { remove } = props;

    const [drawer, setOpenDrawer] = useState(null);
    const closeDrawer = () => setOpenDrawer(null);

    let buttons = [
        {
            icon: icons.edit,
            onClick: () => setOpenDrawer('editBuilding'),
            tooltip: 'Edit'
        },
        {
            confirmationHeader: 'Delete Confirmation',
            confirmationText: 'Are you sure you want to delete?',
            submitting: submitting,
            icon: icons.trash,
            onClick: () => remove(building.id, '/buildings'),
            tooltip: 'Delete'
        }
    ];

    let DrawerContent;
    if (drawer === 'editBuilding') {
        DrawerContent = (
            <EditBuilding id={building.id} closeDrawer={closeDrawer}/>
        );
    }

    return (
        <React.Fragment>
            <Panel buttons={buttons}>
                <SimpleList content={buildingProperties(building)}/>
            </Panel>
            <Drawer onClose={closeDrawer} open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => ({
    building: state.buildings.models[ownProps.buildingId],
    submitting: state.buildings.submitting
});

const mapDispatchToProps = ({
    remove: buildingActions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
